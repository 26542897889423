<template>
  <div>
    <div>
      <span class="v-data-table-title"> {{ tableTitle }} </span>
    </div>
    <div>
      <v-app id="inspire">
        <v-data-table
          v-model="selected"
          :headers="getTableHeaders()"
          :items="tableData"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          :single-select="singleSelect"
          :hide-default-footer="true"
          item-key="name"
          show-expand
          select-enable
          class="elevation-1 quote-result-table"
          :items-per-page="tableData.length"
        >
          <template v-slot:header.margin="{}">
            <span>Margin</span>
            <ul
              role="tablist"
              class="nav nav-pills-info nav-pills btn-group metric-imperial-tab table-tabs"
            >
              <li
                data-toggle="tab"
                role="tablist"
                aria-expanded="true"
                class="nav-item active"
              >
                <a
                  data-toggle="tab"
                  role="tablist"
                  class="nav-link"
                  :aria-expanded="selectedTab ? 'true' : 'false'"
                  :class="selectedTab ? 'active' : ''"
                  @click.prevent="!isQuotesTable && handleTab(true, tableName)"
                  :disabled="isQuotesTable"
                >
                  <div>$</div>
                </a>
              </li>
              <li
                data-toggle="tab"
                role="tablist"
                aria-expanded="true"
                class="nav-item active"
              >
                <a
                  data-toggle="tab"
                  role="tablist"
                  class="nav-link "
                  :aria-expanded="!selectedTab ? 'true' : 'false'"
                  :class="!selectedTab ? 'active' : ''"
                  @click.prevent="!isQuotesTable && handleTab(false, tableName)"
                  :disabled="isQuotesTable"
                >
                  <div>%</div>
                </a>
              </li>
            </ul>
          </template>
          <template v-slot:item.name="{ item }">
            <div v-if="!isQuotesTable">
              <div class="checkbox-pin">
                <div class="custom-control custom-checkbox">
                  <input
                    :id="item.id"
                    type="checkbox"
                    v-model="item.select"
                    class="custom-control-input"
                    @click="checkQuote"
                  />
                  <label :for="item.id" class="custom-control-label"></label>
                </div>
                <span @click="pinUnPinQuote(item)">
                  <svg
                    v-if="tableName == 'pin-quotes'"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM15 11H5V9H15V11Z"
                      fill="#F0C03D"
                    />
                  </svg>
                  <svg
                    v-else
                    width="14"
                    height="18"
                    viewBox="0 0 14 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 2.81523V7.58402C9 8.65223 9.37 9.64413 10 10.4453H4C4.65 9.62506 5 8.63315 5 7.58402V2.81523H9ZM12 0.907715H2C1.45 0.907715 1 1.33691 1 1.86147C1 2.38604 1.45 2.81523 2 2.81523H3V7.58402C3 9.16726 1.66 10.4453 0 10.4453V12.3528H5.97V19.0291L6.97 19.9829L7.97 19.0291V12.3528H14V10.4453C12.34 10.4453 11 9.16726 11 7.58402V2.81523H12C12.55 2.81523 13 2.38604 13 1.86147C13 1.33691 12.55 0.907715 12 0.907715Z"
                      fill="#004225"
                    />
                  </svg>
                </span>
                <span v-if="item.publicRate === true" class="ml-4">
                  <img size="32" src="../../assets/img/globe-1.svg" alt="public rate" />
                </span>
              </div>
            </div>
          </template>
          <template v-slot:item.carrierRate="{ item }">
            <div>
              {{ displayCurrencyValue(item.carrierRate, item.currencyType) }}
            </div>
          </template>
          <template v-slot:item.quotedRate="{ item }">
            <div>
              {{ displayCurrencyValue(item.quotedRate, item.currencyType) }}
            </div>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-simple-table>
                <template v-slot:default>
                  <div>
                    <shipment-expanded-item :item="item" />
                  </div>
                </template>
              </v-simple-table>
            </td>
          </template>
        </v-data-table>
      </v-app>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShipmentExpandedItem from "@/views/common/ShipmentExpandedItem.vue";
import { clearCurrencyValue } from "@/helpers/utility";

export default {
  name: "light-table",
  components: {
    ShipmentExpandedItem
  },
  props: {
    // Component props
    tableTitle: {
      type: String
    },
    tableName: {
      type: String
    },
    tableData: {
      type: Array
    },
    selectedTab: {
      type: Boolean
    },
    isLoader: {
      type: Boolean,
      default: false,
    },
    isQuotesTable: {
      type: Boolean,
    },
  },
  data() {
    return {
      // Component's data properties
      singleSelect: false,
      selected: [],
      expanded: [],
      singleExpand: false,
      headers: [
      ...(!this.isQuotesTable ? [{ text: "Pin/Tag", value: "name" }] : []),
        { text: "Carrier", value: "carrier" },
        { text: "Service level", value: "serviceLevel" },
        { text: "Transit time (Days)", value: "transitTime" },
        {
          text: "Carrier rate",
          value: "carrierRate",
          class: "custom-th-class"
        },
        { text: "Client rate", value: "quotedRate", class: "custom-th-class" },
        { text: "Currency Type", value: "currencyType", class: "custom-th-class" },
        {
          text: "Margin",
          value: "margin",
          class: "custom-th-class margin-col-header",
          sortable: false
        },
        {
          text: "",
          value: "data-table-expand",
          class: "custom-th-class"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["userIsBroker"])
  },
  methods: {
    /**
     * Handles the tab selection and emits an event to handle margin based on the selected tab.
     * @param {boolean} isDollar - Indicates whether the selected tab is related to dollars.
     * @param {string} tableName - The name of the selected tab.
     */
    handleTab(isDollar, tableName) {
      this.$emit("handle-margin", isDollar, tableName);
    },
    /**
     * Checks the quote and emits an event for quote selection.
     */
    checkQuote() {
      this.$emit("quote-selection");
    },
    /**
     * Pins or unpins the specified quote item and emits an event.
     * @param {Object} item - The quote item to be pinned or unpinned.
     */
    pinUnPinQuote(item) {
      this.$emit("pin-unpin-quote", item);
    },
    /**
     * Displays the currency value based on the specified currency type.
     * @param {number} currencyValue - The currency value to be displayed.
     * @param {string} currencyType - The currency type.
     * @returns {string} - The formatted currency value.
     */
    displayCurrencyValue(currencyValue, currencyType) {
      return clearCurrencyValue(currencyValue, currencyType);
    },
    /**
     * Gets the table headers based on user mode.
     *
     * @returns {Array} An array of table headers based on user mode.
     */
    getTableHeaders() {
      // Check if the user is a broker
      if (this.userIsBroker) {
        // If the user is a broker, return all headers
        return this.headers;
      } else {
        // If the user is not a broker, exclude CLIENT RATE and MARGIN columns
        return this.headers.filter(
          header => header.value !== "quotedRate" && header.value !== "margin"
        );
      }
    },
  },
  mounted() {
    this.$emit('set-best-quotes-from-rates')
  }
};
</script>

<style></style>
