<template>
  <div class="wrapper">
    <div class="body-container row add-edit-carrier">
      <loader :isLoader="showLoader"></loader>
      <left-panel :activeMenuName="activeMenuName"></left-panel>
      <div class="col-lg-10 right-panel-scroll plr-30 mt-4">
        <div class="row right-panel-header">
          <div class="col-md-4">
            <search-bar />
          </div>
          <div class="col-md-4"></div>
          <div class="col-md-4">
            <profile-header />
          </div>
        </div>

        <page-heading-card
          title="Carrier"
          subTitle=""
          buttonName="See all saved carrier"
          imageName="carriers.png"
          addRecordScreenName="carriers"
        />

        <div class="mt-5 mb-4 section-b">
          <div class="row mb-4">
            <div class="col-md-6 headline-30">
              {{ carrierName }}
            </div>
            <div class="col-md-6">
              <div class="save-carriers">
                <base-button
                  nativeType="submit"
                  type="primary"
                  class="btn-theme-24876E"
                  @click="updateCarrier"
                >
                  Save
                </base-button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group has-label col-md-4">
              <base-input
                type="text"
                name="Carrier Name"
                label="Carrier Name"
                class="input-v1"
                rules="required"
                v-model="carrierName"
              ></base-input>
            </div>
            <div
              v-if="carrierType === 'other'"
              class="form-group has-label col-md-4"
            >
              <base-input
                type="text"
                name="Carrier Api Key"
                label="Carrier API"
                class="input-v1"
                v-model="tariffApiKey"
              ></base-input>
            </div>
            <div
              v-else
              v-for="(value, type) in carrierCredentials"
              :key="type"
              class="form-group has-label col-md-4"
            >
              <!-- TODO: set password type, add eye icon to view -->
              <base-input
                v-model="carrierCredentials[type]"
                type="text"
                :label="type"
                class="input-v1"
              />
            </div>
          </div>

          <collapse :multiple-active="false" class="carrier-collapse">
            <div class="card">
              <div
                role="tab"
                class="card-header"
                :aria-expanded="activeCollapse"
              >
                <a
                  data-toggle="collapse"
                  data-parent="#accordion"
                  :href="`#`"
                  @click.prevent="toggleCollapse"
                >
                  <slot name="title">
                    <h5 class="mb-0 btn btn-link w-100 text-primary text-left">
                      Additional Details
                      <i class="ni ni-bold-down float-right pt-1"></i>
                    </h5>
                  </slot>
                  <i class="tim-icons icon-minimal-down"></i>
                </a>
              </div>
              <collapse-transition>
                <div
                  v-show="activeCollapse"
                  :id="`content-5`"
                  role="tabpanel"
                  class="collapsed"
                >
                  <div class="card-body collapsed-shipment">
                    <div class="row">
                      <div class="col-md-4">
                        <base-input
                          type="text"
                          class="input-v1"
                          value=""
                          label="Contact Name"
                          placeholder="Contact Name"
                          v-model="contactName"
                        >
                        </base-input>
                      </div>
                      <div class="col-md-4">
                        <base-input label="Valid from" class="input-v1">
                          <flat-picker
                            :config="{ dateFormat: 'M J, Y' }"
                            class="form-control datepicker"
                            v-model="dateAdded"
                          >
                          </flat-picker>
                        </base-input>
                      </div>
                      <div class="col-md-4">
                        <base-input
                          type="text"
                          label="Valid To"
                          class="input-v1"
                        >
                          <flat-picker
                            :config="{ dateFormat: 'M J, Y' }"
                            class="form-control datepicker"
                            v-model="dateExpire"
                          >
                          </flat-picker>
                        </base-input>
                      </div>
                    </div>

                    <!-- Carrier Contacts Table -->
                    <carrier-contact-form :contactData="contactData" />

                    <!-- Accessorials -->
                    <div class="carrier-contact-table mt-5">
                      <div>
                        <span class="v-data-table-title"> Accessorials </span>
                      </div>

                      <div
                        v-for="(item, index) in accessorialsData"
                        :key="index"
                        class="row mb-2 mt-3"
                      >
                        <div class="col-md-2 form-group has-label">
                          <label>Accessorials</label>
                          <choices-single
                            class="quantity-select-dropdown"
                            :id="
                              item.keyDetails ? item.keyDetails.identifier : ''
                            "
                            :options="item.accessorialDropdownList"
                            v-model="item.accessorial"
                            @input="event => selectAccessorial(event, index)"
                          >
                            <option value="0">Select Accessorial</option>
                          </choices-single>
                        </div>
                        <div class="col-md-2 form-group has-label">
                          <label>Cost method</label>
                          <choices-single
                            class="quantity-select-dropdown cost-method-dropdown"
                            :options="item.dataTypeDropdownList"
                            :id="
                              item.keyDetails
                                ? item.costMethod
                                    .replace(/\+/g, '')
                                    .replace(/\s+/g, '') + index
                                : ''
                            "
                            v-model="item.costMethod"
                            @input="event => selectDataType(event, index)"
                          >
                            <option value="0">Select Datatype</option>
                          </choices-single>
                        </div>
                        <div class="col-md-2 form-group has-label">
                          <label>Value</label>
                          <div>
                            <span
                              class="data-type-input right input-v1"
                              v-if="
                                item.costMethod.toLowerCase() == 'percentage'
                              "
                            >
                              <input
                                type="number"
                                step="0.01"
                                class="form-control"
                                placeholder="00.00"
                                v-model="item.value.percentageValue"
                              />
                            </span>

                            <span
                              class="data-type-input left input-v1"
                              v-else-if="
                                item.costMethod.toLowerCase() == 'fixed'
                              "
                            >
                              <input
                                type="number"
                                step="0.01"
                                class="form-control"
                                placeholder="00.00"
                                v-model="item.value.dollarValue"
                              />
                            </span>

                            <span
                              v-else-if="
                                item.costMethod.toLowerCase() ==
                                  'fixed + percentage'
                              "
                            >
                              <span
                                class="data-type-input right data-type-input-space input-v1"
                              >
                                <input
                                  type="number"
                                  step="0.01"
                                  class="form-control"
                                  placeholder="00.00"
                                  v-model="item.value.percentageValue"
                                />
                              </span>

                              <span
                                class="data-type-input left data-type-input-space input-v1"
                              >
                                <input
                                  type="number"
                                  step="0.01"
                                  class="form-control"
                                  placeholder="00.00"
                                  v-model="item.value.dollarValue"
                                />
                              </span>
                            </span>

                            <div
                              v-else-if="
                                item.costMethod.toLowerCase() ==
                                  weightedPercentageLabel ||
                                  item.costMethod == costTypeWeightedPercentage
                              "
                            >
                              <div
                                v-for="(weightedPercentage,
                                key) in item.weightedPercentage"
                                class="weighted-percentage"
                                :key="key"
                              >
                                <span
                                  class="left data-type-input-space wp-mr-5 input-v1"
                                >
                                  <input
                                    type="number"
                                    step="0.01"
                                    class="form-control"
                                    placeholder="0"
                                    v-model="weightedPercentage.weightValue"
                                  />
                                </span>

                                <span
                                  class="data-type-input right data-type-input-space wp-mr-5 input-v1"
                                >
                                  <input
                                    type="number"
                                    step="0.01"
                                    class="form-control"
                                    placeholder="00.00"
                                    v-model="weightedPercentage.percentageValue"
                                  />
                                </span>

                                <span
                                  class="mt-3 weighted-percentage-delete"
                                  v-on:click="
                                    removeWeightedPercentage(index, key)
                                  "
                                >
                                  <img src="../assets/img/home/trash.png" />
                                </span>
                              </div>
                              <div class="mb-1">
                                <base-button
                                  type="primary"
                                  class="btn-theme-24876E weighted-percentage-btn"
                                  v-on:click="addWeightedPercentage(index)"
                                >
                                  <img
                                    src="../assets/img/home/plus-round.png"
                                  />
                                  Add Additional Weighted Percentage
                                </base-button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <base-input
                            type="text"
                            class="input-v1"
                            label="Last update"
                          >
                            <flat-picker
                              :config="{ dateFormat: 'M J, Y' }"
                              class="form-control datepicker"
                              v-model="item.lastUpdated"
                            >
                            </flat-picker>
                          </base-input>
                        </div>
                        <div class="col-md-2">
                          <base-input
                            type="text"
                            class="input-v1"
                            label="Valid To"
                          >
                            <flat-picker
                              :config="{ dateFormat: 'M J, Y' }"
                              class="form-control datepicker"
                              v-model="item.expiryDate"
                            >
                            </flat-picker>
                          </base-input>
                        </div>
                        <div class="col-md-1">
                          <base-input
                            type="text"
                            class="input-v1"
                            label="User name"
                            name="User name"
                            v-model="item.userUpdated"
                          >
                          </base-input>
                        </div>
                        <div class="col-md-1">
                          <base-button
                            type="primary"
                            class="btn-theme-EFEBEB action-round"
                            @click="removeAccessorial(index)"
                          >
                            <img src="../assets/img/home/trash.png" />
                          </base-button>
                        </div>
                      </div>
                    </div>

                    <base-button
                      type="primary"
                      class="btn-theme-24876E mb-1"
                      v-on:click="addAccessorial"
                    >
                      <img src="../assets/img/home/plus-round.png" />
                      Add additional accessorial
                    </base-button>

                    <!-- Carrier Tariff -->
                    <carrier-tariff
                      :carrierName="carrierName"
                      :tarrifData="tarrifData"
                    />
                  </div>
                </div>
              </collapse-transition>
            </div>
          </collapse>

          <modal
            :show.sync="confirmModal"
            modal-classes="modal-dialog-centered confrim-quote-modal"
          >
            <div>
              It looks like you have some unsaved changes. If you leave before
              saving, your changes will be lost. Press Cancel to return to the
              page or continue to proceed without saving
            </div>

            <template slot="footer">
              <base-button
                type="secondary"
                class="ml-auto"
                @click="confirmModal = false"
                >Cancel
              </base-button>
              <base-button
                type="primary"
                class="pallet-theme-btn"
                @click="continueRedirect"
              >
                Continue
              </base-button>
            </template>
          </modal>
        </div>

        <div class="mt-4 mb-4 section-b">
          <current-tariff :tariffApiKey="tariffApiKey" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftPanel from "../layout/LeftPanel";
import Loader from "../layout/Loader";
import SearchBar from "@/views/components/SearchBar";
import ProfileHeader from "@/layout/ProfileHeader";
import PageHeadingCard from "@/views/components/PageHeadingCard";
import CarrierContactForm from "@/views/carriers/CarrierContactForm";
import CarrierTariff from "@/views/carriers/CarrierTariff";
import Collapse from "@/components/Collapse/Collapse";
import { CollapseTransition } from "vue2-transitions";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { API, masterAPI } from "@/api/API";
import moment from "moment";
import ChoicesSingle from "@/components/SingleSelect";
import Modal from "@/components/Modal.vue";
import CurrentTariff from "@/views/tariff/CurrentTariff";

export default {
  bodyClass: "carriers",
  components: {
    LeftPanel,
    Loader,
    SearchBar,
    ProfileHeader,
    PageHeadingCard,
    CarrierContactForm,
    CarrierTariff,
    Collapse,
    CollapseTransition,
    flatPicker,
    ChoicesSingle,
    Modal,
    CurrentTariff
  },
  props: {},
  data: function() {
    return {
      activeMenuName: "Carriers",
      carrierName: "",
      carrierCredentials: [],
      carrierType: "",
      contactName: "",
      dateAdded: "",
      dateExpire: "",
      tariffApiKey: "",
      singleSelect: false,
      contactData: [],
      tarrifData: [],
      accessorialsHeaders: [
        {
          text: "Accessorial",
          value: "accessorial",
          sortable: false,
          width: "189px"
        },
        {
          text: "Cost Method",
          value: "costMethod",
          sortable: false,
          width: "174px"
        },
        { text: "Value", value: "value", sortable: false, width: "195px" },
        { text: "Last Updated", value: "lastUpdated", sortable: false },
        { text: "Expiry Date", value: "expiryDate", sortable: false },
        {
          text: "User Who Updated",
          value: "userUpdated",
          sortable: false,
          width: "30px"
        },
        {
          text: "Action",
          value: "edit",
          class: "custom-th-class",
          sortable: false,
          width: "25px"
        }
      ],
      accessorialsData: [],
      accessorialsListFromDb: [],
      dbCarrierName: "",
      dbContactName: "",
      dbDateAdded: "",
      dbDateExpire: "",
      dbContactData: [],
      dbAccessorialsData: [],
      dbTarrifData: [],
      confirmModal: false,
      supportedAccessorials: [],
      supportedAdditionals: [],
      supportedAdditionalsAccessorials: [],
      showLoader: false,
      weightedPercentageLabel: "weighted percentage",
      costTypeWeightedPercentage: "weightedPercentage",
      lastSelectedAccessorial: {
        index: null,
        item: null
      },
      lastSelectedDatatype: {
        index: null,
        item: null
      },
      lastIndexAccessorial: null,
      lastAccessorialName: null,
      dataTypeDropdownListDb: [
        {
          id: 1,
          text: "percentage"
        },
        {
          id: 2,
          text: "fixed"
        },
        {
          id: 3,
          text: "fixed + percentage"
        },
        {
          id: 4,
          text: this.weightedPercentageLabel
        }
      ],
      activeCollapse: false
    };
  },
  watch: {},
  computed: {
    carrierId() {
      return +this.$route.params.id;
    }
  },
  methods: {
    async getSupportedAccessorials() {
      let accessorialsItems = [];
      let response = await masterAPI(
        API.API_ENDPOINT.supportedAccessorials,
        API.API_METHOD.get,
        undefined,
        undefined
      );
      if (response.status == 200) {
        accessorialsItems = [response.data];
        this.getSupportedAdditionals(accessorialsItems);
      } else {
        this.getSupportedAdditionals(accessorialsItems);
      }
    },
    async getSupportedAdditionals(accessorialsItems) {
      let response = await masterAPI(
        API.API_ENDPOINT.supportedAdditionals,
        API.API_METHOD.get,
        undefined,
        undefined
      );
      if (response.status == 200) {
        let mergeItems = [...accessorialsItems, ...[response.data]];
        this.getCarrierId(mergeItems);
      } else {
        let mergeItems = [...accessorialsItems, ...[]];
        this.getCarrierId(mergeItems);
      }
    },
    async getCarrierId(itemsList) {
      if (this.carrierId) {
        let response = await masterAPI(
          API.API_ENDPOINT.carrier,
          API.API_METHOD.get,
          this.carrierId,
          undefined
        );

        if (response.status == 200) {
          let carrierData = response.data;
          this.carrierName = carrierData.name;
          this.carrierType = carrierData.carrierType;
          if (carrierData.carrierType === "other") {
            this.tariffApiKey = carrierData.tariffApiKey;
          } else {
            this.carrierCredentials = carrierData.metadata?.credentials;
          }
          this.dateAdded = moment(carrierData.createdAt).format("MMM Do, YYYY");
          let lastUpdated = moment(carrierData.updatedAt).format(
            "MMM Do, YYYY"
          );

          this.dbCarrierName = carrierData.name;
          this.dbDateAdded = moment(carrierData.createdAt).format(
            "MMM Do, YYYY"
          );

          this.tarrifData = [];
          let tarrifObj = {
            select: false,
            tariffName: "Tariff Name",
            lastUpdated: lastUpdated,
            tariffActive: "active",
            startDate: "Dec 31th, 2022",
            endDate: "Jan 29th, 2023",
            isEdit: false,
            id: 0,
            carrierDetail: carrierData
          };
          this.tarrifData.push(tarrifObj);

          //
          this.dbTarrifData = [];
          let tarrifObjDb = {
            select: false,
            tariffName: "Tariff Name",
            lastUpdated: lastUpdated,
            tariffActive: "active",
            startDate: "Dec 31th, 2022",
            endDate: "Jan 29th, 2023",
            isEdit: false,
            id: 0,
            carrierDetail: carrierData
          };
          this.dbTarrifData.push(tarrifObjDb);

          // Accessorials
          //if (carrierData.metadata) {
          this.accessorialsData = [];
          this.dbAccessorialsData = [];
          let items = [];
          let supportedItems = [];
          itemsList.map((item, index) => {
            for (const [key, value] of Object.entries(item)) {
              items.push({
                id: index + 1,
                text: value.name,
                detail: value,
                key: key
              });

              supportedItems.push({
                id: index + 1,
                text: value.name,
                detail: value,
                key: key
              });
            }
          });

          let accessorialsList = carrierData.metadata
            ? carrierData.metadata.accessorials
            : [];
          let additionalsList = carrierData.metadata
            ? carrierData.metadata.additionals
            : [];

          let mergeArray = [...accessorialsList, ...additionalsList];
          mergeArray.map((item, index) => {
            if (item) {
              let weightedPercentageDetail = [];
              if (item.type == this.costTypeWeightedPercentage) {
                if (item.value.conditions) {
                  for (const [key, value] of Object.entries(
                    item.value.conditions
                  )) {
                    weightedPercentageDetail.push({
                      weightValue: key,
                      percentageValue: value
                    });
                  }
                }
              }
              let accessorialObj = {
                accessorial: item.name,
                costMethod: item.type,
                value: {
                  dollarValue:
                    item.type.toLowerCase() == "fixed"
                      ? item.value
                      : item.value.dollarValue,
                  percentageValue:
                    item.type.toLowerCase() == "percentage"
                      ? item.value
                      : item.value.percentageValue
                },
                lastUpdated: lastUpdated,
                expiryDate: "",
                userUpdated: "",
                isEdit: false,
                id: index + 1,
                alreadySave: true,
                accessorialDropdownList: items,
                dataTypeDropdownList: [
                  {
                    id: 1,
                    text: "percentage"
                  },
                  {
                    id: 2,
                    text: "fixed"
                  },
                  {
                    id: 3,
                    text: "fixed + percentage"
                  },
                  {
                    id: 4,
                    text: this.weightedPercentageLabel
                  }
                ],
                weightedPercentage: weightedPercentageDetail,
                keyDetails: item
              };
              this.accessorialsData.push(accessorialObj);

              let accessorialObjDb = {
                accessorial: item.name,
                costMethod: item.type,
                value: {
                  dollarValue:
                    item.type.toLowerCase() == "fixed"
                      ? item.value
                      : item.value.dollarValue,
                  percentageValue:
                    item.type.toLowerCase() == "percentage"
                      ? item.value
                      : item.value.percentageValue
                },
                lastUpdated: lastUpdated,
                expiryDate: "",
                userUpdated: "",
                isEdit: false,
                id: index + 1,
                alreadySave: true,
                accessorialDropdownList: items,
                dataTypeDropdownList: [
                  {
                    id: 1,
                    text: "percentage"
                  },
                  {
                    id: 2,
                    text: "fixed"
                  },
                  {
                    id: 3,
                    text: "fixed + percentage"
                  },
                  {
                    id: 4,
                    text: this.weightedPercentageLabel
                  }
                ],
                weightedPercentage: weightedPercentageDetail,
                keyDetails: item
              };
              this.dbAccessorialsData.push(accessorialObjDb);
            }
          });
          this.accessorialsListFromDb = items;
          this.supportedAdditionalsAccessorials = supportedItems;
          //}

          this.contactData = [];
          this.dbContactData = [];

          if (carrierData.contacts) {
            carrierData.contacts.map((item, index) => {
              let contactObj = {
                name: item.name,
                email: item.email,
                phoneNumber: item.phoneNumber,
                startDate: item.startDate,
                endDate: item.endDate,
                isEdit: false,
                id: index,
                alreadySave: true
              };

              this.contactData.push(contactObj);
              this.dbContactData.push(contactObj);
            });
          }
        }
      }
    },
    updateCarrier: async function() {
      this.showLoader = true;
      let contacts = [];
      this.showLoader = true;
      this.contactData.map(item => {
        contacts.push({
          name: item.name,
          email: item.email,
          phoneNumber: item.phoneNumber,
          startDate: item.startDate,
          endDate: item.endDate
        });
      });

      const auth = {};
      if (this.carrierType === "other") {
        auth.tariffApiKey = this.tariffApiKey;
        auth.metadata = {
          credentials: {
            tariffApiKey: this.tariffApiKey
          }
        };
      } else {
        auth.metadata = {
          credentials: this.carrierCredentials
        };
      }
      let bodyPayload = JSON.stringify({
        name: this.carrierName,
        ...auth,
        contacts: contacts
      });

      let response = await masterAPI(
        API.API_ENDPOINT.carrier,
        API.API_METHOD.put,
        this.carrierId,
        bodyPayload
      );

      if (response.status == 200) {
        if (this.accessorialsData.length == 0) {
          this.showLoader = false;
          this.$toast.success("Updated successful", { timeout: 100 });
          this.getSupportedAccessorials();
          // this.$router.push({ name: "carriers" });
        } else {
          this.accessorialsAPI();
        }
      } else {
        if (this.accessorialsData.length == 0) {
          this.showLoader = false;
          this.$toast.error(response.data.message, { timeout: 1000 });
        } else {
          this.accessorialsAPI();
        }
      }
    },
    accessorialsAPI() {
      let apiCallIndex = 0;
      this.accessorialsData.map(async item => {
        let costValue = "";
        let type = item.costMethod.toLowerCase();
        let isValid = true;
        let isWeightValue = false;
        let isPercentageValue = false;

        if (item.costMethod.toLowerCase() == "percentage") {
          costValue = item.value.percentageValue;
        } else if (item.costMethod.toLowerCase() == "fixed") {
          costValue = item.value.dollarValue;
        } else if (item.costMethod.toLowerCase() == "fixed + percentage") {
          costValue = {
            dollarValue: item.value.percentageValue,
            percentageValue: item.value.dollarValue
          };
        } else if (
          item.costMethod.toLowerCase() == this.weightedPercentageLabel ||
          item.costMethod == this.costTypeWeightedPercentage
        ) {
          let conditionsObject = {};
          item.weightedPercentage.forEach(function(items) {
            conditionsObject[items.weightValue] = items.percentageValue
              ? parseFloat(items.percentageValue)
              : items.percentageValue;

            if (items.percentageValue == "") {
              isValid = false;
              isWeightValue = false;
              isPercentageValue = true;
            }

            if (items.weightValue == "") {
              isValid = false;
              isPercentageValue = false;
              isWeightValue = true;
            }
          });
          costValue = {
            conditions: conditionsObject
          };

          type = this.costTypeWeightedPercentage;
        }
        if (isValid) {
          if (item.keyDetails) {
            let identifier = item.keyDetails.identifier;
            if (identifier == "fuelSurcharge") {
              // Update Additional API call
              let endPoint =
                API.API_ENDPOINT.carrier +
                "/" +
                this.carrierId +
                "/" +
                API.API_ENDPOINT.updateAdditional;
              let additionalPayload = {
                identifier: item.keyDetails.identifier,
                type: type,
                value: costValue
              };
              let res = await masterAPI(
                endPoint,
                API.API_METHOD.put,
                undefined,
                JSON.stringify(additionalPayload)
              );

              if (res.status == 200) {
                apiCallIndex = apiCallIndex + 1;
              } else {
                apiCallIndex = apiCallIndex + 1;
              }
            } else {
              // Update Accessorial API call
              let endPoint =
                API.API_ENDPOINT.carrier +
                "/" +
                this.carrierId +
                "/" +
                API.API_ENDPOINT.updateAccessorial;
              let accessorialsPayload = {
                identifier: item.keyDetails.identifier,
                type: type,
                value: costValue
              };
              let res = await masterAPI(
                endPoint,
                API.API_METHOD.put,
                undefined,
                JSON.stringify(accessorialsPayload)
              );

              if (res.status == 200) {
                apiCallIndex = apiCallIndex + 1;
              } else {
                apiCallIndex = apiCallIndex + 1;
              }
            }
          } else {
            let identifier = this.getIdentifierKey(item.accessorial);
            if (identifier) {
              if (identifier == "fuelSurcharge") {
                // Add Additional API call
                let endPoint =
                  API.API_ENDPOINT.carrier +
                  "/" +
                  this.carrierId +
                  "/" +
                  API.API_ENDPOINT.addAdditional;
                let additionalPayload = {
                  identifier: this.getIdentifierKey(item.accessorial),
                  type: type,
                  value: costValue
                };

                let res = await masterAPI(
                  endPoint,
                  API.API_METHOD.post,
                  undefined,
                  JSON.stringify(additionalPayload)
                );

                if (res.status == 200) {
                  apiCallIndex = apiCallIndex + 1;
                } else {
                  apiCallIndex = apiCallIndex + 1;
                }
              } else {
                // Add Accessorial API call
                let endPoint =
                  API.API_ENDPOINT.carrier +
                  "/" +
                  this.carrierId +
                  "/" +
                  API.API_ENDPOINT.addAccessorial;
                let accessorialsPayload = {
                  identifier: this.getIdentifierKey(item.accessorial),
                  type: type,
                  value: costValue
                };

                let res = await masterAPI(
                  endPoint,
                  API.API_METHOD.post,
                  undefined,
                  JSON.stringify(accessorialsPayload)
                );

                if (res.status == 200) {
                  apiCallIndex = apiCallIndex + 1;
                } else {
                  apiCallIndex = apiCallIndex + 1;
                }
              }
            } else {
              apiCallIndex = apiCallIndex + 1;
            }
          }

          if (apiCallIndex == this.accessorialsData.length) {
            this.showLoader = false;
            this.$toast.success("Updated successful", { timeout: 100 });
            this.getSupportedAccessorials();
            //this.$router.push({ name: "carriers" });
          }
        } else {
          if (isWeightValue) {
            this.showLoader = false;
            this.$toast.error("Weight field required", { timeout: 1000 });
          }

          if (isPercentageValue) {
            this.showLoader = false;
            this.$toast.error("Percentage field required", { timeout: 1000 });
          }
        }
      });
    },
    getIdentifierKey(accessorial) {
      let accessorialKey = this.supportedAdditionalsAccessorials
        .map(items => {
          if (items.text == accessorial) {
            return items;
          }
        })
        .filter(function(element) {
          return element !== undefined;
        });

      return accessorialKey.length > 0 ? accessorialKey[0].key : null;
    },
    selectAccessorial: async function(selectedItem, i) {
      if (selectedItem == "Fuel Surcharge") {
        let dataTypeList = this.accessorialsData[i].dataTypeDropdownList;
        let existsDataType = dataTypeList
          .map(item => {
            if (
              item.text == this.weightedPercentageLabel ||
              item.text == this.costTypeWeightedPercentage
            ) {
              return item;
            }
          })
          .filter(function(element) {
            return element !== undefined;
          });

        if (existsDataType.length == 0) {
          let weightedPercentageOption = {
            id: 4,
            text: this.weightedPercentageLabel
          };
          this.accessorialsData[i].dataTypeDropdownList.push(
            weightedPercentageOption
          );
        }
      } else {
        let list = this.accessorialsData[i].dataTypeDropdownList;
        let selectedIndex = list
          .map(item => item.text)
          .indexOf(this.weightedPercentageLabel);
        if (selectedIndex != -1) {
          this.accessorialsData[i].dataTypeDropdownList.splice(
            selectedIndex,
            1
          );
        }
      }

      let dataTypeList = this.accessorialsData[i].dataTypeDropdownList;

      if (dataTypeList.length == 0) {
        this.dataTypeDropdownListDb.map((values, index) => {
          let resetObj = {
            id: index + 1,
            text: values.text
          };
          this.accessorialsData[i].dataTypeDropdownList.push(resetObj);
        });
      }

      this.accessorialsData.map(item => {
        if (item.accessorial == selectedItem && item.costMethod != "") {
          let selectedIndex = item.dataTypeDropdownList
            .map(item => item.text)
            .indexOf(item.costMethod);
          this.accessorialsData[i].dataTypeDropdownList.splice(
            selectedIndex,
            1
          );
        }
      });
    },
    selectDataType: async function(selectedItem, i) {
      this.lastSelectedDatatype.item = selectedItem;
      this.lastSelectedDatatype.index = i;
      // let list = this.accessorialsData[i].dataTypeDropdownList;
      // let selectedIndex = list.map((item) => item.text).indexOf(selectedItem);
      // this.accessorialsData[i].dataTypeDropdownList.splice(selectedIndex, 1);
      // this.accessorialsData[i].value = {
      //     dollarValue : '',
      //     percentageValue : '',
      // }
    },
    addAccessorial: function() {
      if (this.lastIndexAccessorial) {
        let lastDetail = this.accessorialsData[this.lastIndexAccessorial];
        if (lastDetail.accessorial == "") {
          this.$toast.error("Please select a accessorial");
        } else if (lastDetail.costMethod == "") {
          this.$toast.error("Please select a data type");
        } else if (lastDetail.costMethod == "fixed") {
          if (lastDetail.value.dollarValue == "") {
            this.$toast.error("Dollar field is required");
          } else {
            this.addNewAccessorial();
          }
        } else if (lastDetail.costMethod == "percentage") {
          if (lastDetail.value.percentageValue == "") {
            this.$toast.error("Percentage field is required");
          } else {
            this.addNewAccessorial();
          }
        } else if (lastDetail.costMethod == "fixed + percentage") {
          if (lastDetail.value.dollarValue == "") {
            this.$toast.error("Dollar field is required");
          } else if (lastDetail.value.percentageValue == "") {
            this.$toast.error("Percentage field is required");
          } else {
            this.addNewAccessorial();
          }
        } else if (lastDetail.costMethod == "weighted percentage") {
          let weightValueList = lastDetail.weightedPercentage
            .map(item => {
              if (item.weightValue == "") {
                return item;
              }
            })
            .filter(function(element) {
              return element !== undefined;
            });

          let percentageValueList = lastDetail.weightedPercentage
            .map(item => {
              if (item.percentageValue == "") {
                return item;
              }
            })
            .filter(function(element) {
              return element !== undefined;
            });

          if (weightValueList.length > 0 && percentageValueList.length > 0) {
            this.$toast.error("Weight field required", { timeout: 1000 });
          } else if (weightValueList.length > 0) {
            this.$toast.error("Weight field required", { timeout: 1000 });
          } else if (percentageValueList.length > 0) {
            this.$toast.error("Percentage field required", { timeout: 1000 });
          } else {
            this.addNewAccessorial();
          }
        } else {
          this.addNewAccessorial();
        }
      } else {
        this.addNewAccessorial();
      }
    },
    addNewAccessorial() {
      this.removeExistingAccessorials();
      let index = this.accessorialsData.length;
      let accessorialsObj = {
        accessorial: "",
        costMethod: "",
        value: {
          dollarValue: "",
          percentageValue: ""
        },
        lastUpdated: "",
        expiryDate: "",
        userUpdated: "",
        isEdit: true,
        id: index + 1,
        alreadySave: true,
        accessorialDropdownList: this.accessorialsListFromDb,
        dataTypeDropdownList: [
          {
            id: 1,
            text: "percentage"
          },
          {
            id: 2,
            text: "fixed"
          },
          {
            id: 3,
            text: "fixed + percentage"
          },
          {
            id: 4,
            text: this.weightedPercentageLabel
          }
        ],
        weightedPercentage: [
          {
            weightValue: "",
            percentageValue: ""
          }
        ]
      };

      this.accessorialsData.push(accessorialsObj);
      let lastIndex = this.accessorialsData.length - 1;
      this.lastIndexAccessorial = lastIndex;
    },
    removeExistingAccessorials() {
      // this.accessorialsData.map(async (item) => {
      //   let identifier = item.keyDetails.identifier;
      //   this.accessorialsListFromDb.map(async (newItem,index) => {
      //     if (identifier == newItem.key) {
      //       this.accessorialsListFromDb.splice(index, 1);
      //     }
      //   });
      // });
    },
    removeAccessorial: async function(index) {
      this.accessorialsData.splice(index, 1);
    },
    showEditAccessorial: function(item, i) {
      this.accessorialsData[i].isEdit = true;
      if (item.keyDetails) {
        let identifier = item.keyDetails.identifier;
        let type = item.costMethod.replace(/\+ /g, "").replace(/\s+/g, "") + i;
        setTimeout(function() {
          document.querySelector(
            "#" + identifier + " .choices__item"
          ).innerText = item.accessorial;
          document.querySelector("#" + type + " .choices__item").innerText =
            item.costMethod;
        }, 30);
      }

      if (item.accessorial == "Fuel Surcharge") {
        let dataTypeList = this.accessorialsData[i].dataTypeDropdownList;
        let existsDataType = dataTypeList
          .map(item => {
            if (
              item.text == this.weightedPercentageLabel ||
              item.text == this.costTypeWeightedPercentage
            ) {
              return item;
            }
          })
          .filter(function(element) {
            return element !== undefined;
          });

        if (existsDataType.length == 0) {
          let weightedPercentageOption = {
            id: 4,
            text: this.weightedPercentageLabel
          };
          this.accessorialsData[i].dataTypeDropdownList.push(
            weightedPercentageOption
          );
        }
      } else {
        let list = this.accessorialsData[i].dataTypeDropdownList;
        let selectedIndex = list
          .map(item => item.text)
          .indexOf(this.weightedPercentageLabel);
        if (selectedIndex != -1) {
          this.accessorialsData[i].dataTypeDropdownList.splice(
            selectedIndex,
            1
          );
        }
      }
    },
    hideEditAccessorial: function(i) {
      this.accessorialsData[i].isEdit = false;
      if (!this.accessorialsData[i].alreadySave) {
        this.removeAccessorial(i);
      }
    },
    checkChangeData: function() {
      if (this.dbCarrierName != this.carrierName) {
        this.confirmModal = true;
      } else if (this.dbContactName != this.contactName) {
        this.confirmModal = true;
      } else if (this.dbDateAdded != this.dateAdded) {
        this.confirmModal = true;
      } else if (this.dbDateExpire != this.dateExpire) {
        this.confirmModal = true;
      } else if (this.dbContactData.length != this.contactData.length) {
        this.confirmModal = true;
      } else if (this.dbContactData.length == this.contactData.length) {
        let contactList = this.dbContactData
          .map((item, index) => {
            let contactItem = this.contactData[index];
            if (
              item.name != contactItem.name ||
              item.email != contactItem.email ||
              item.phoneNumber != contactItem.phoneNumber ||
              item.startDate != contactItem.startDate ||
              item.endDate != contactItem.endDate
            ) {
              return item;
            }
          })
          .filter(function(element) {
            return element !== undefined;
          });

        if (contactList.length > 0) {
          this.confirmModal = true;
        } else {
          if (this.dbAccessorialsData.length != this.accessorialsData.length) {
            this.confirmModal = true;
          } else if (
            this.dbAccessorialsData.length == this.accessorialsData.length
          ) {
            let accessorialsList = this.dbAccessorialsData
              .map((item, index) => {
                let newItem = this.accessorialsData[index];
                if (
                  item.accessorial != newItem.accessorial ||
                  item.costMethod != newItem.costMethod ||
                  item.value.dollarValue != newItem.value.dollarValue ||
                  item.value.percentageValue != newItem.value.percentageValue ||
                  item.lastUpdated != newItem.lastUpdated ||
                  item.expiryDate != newItem.expiryDate
                ) {
                  return item;
                }
              })
              .filter(function(element) {
                return element !== undefined;
              });

            if (accessorialsList.length > 0) {
              this.confirmModal = true;
            } else {
              if (this.dbTarrifData.length != this.tarrifData.length) {
                this.confirmModal = true;
              } else if (this.dbTarrifData.length == this.tarrifData.length) {
                let changeItem = this.dbTarrifData
                  .map((item, index) => {
                    let newItem = this.tarrifData[index];
                    if (
                      item.tariffName != newItem.tariffName ||
                      item.lastUpdated != newItem.lastUpdated ||
                      item.tariffActive != newItem.tariffActive ||
                      item.startDate != newItem.startDate ||
                      item.endDate != newItem.endDate
                    ) {
                      return item;
                    }
                  })
                  .filter(function(element) {
                    return element !== undefined;
                  });

                if (changeItem.length > 0) {
                  this.confirmModal = true;
                } else {
                  this.continueRedirect();
                }
              }
            }
          }
        }
      } else {
        this.continueRedirect();
      }
    },
    continueRedirect: function() {
      this.$router.back();
      // this.$router
      //   .push({ path: "/carriers", name: "carriers" })
      //   .catch(() => {});
    },
    setDollarPercentageValue: function(item) {
      let value = "";
      let percentageValue = item.value.percentageValue;
      let dollarValue = item.value.dollarValue;

      if (item.costMethod.toLowerCase() == "percentage") {
        value = percentageValue != "" ? percentageValue + "%" : "";
      } else if (item.costMethod.toLowerCase() == "fixed") {
        value = dollarValue != "" ? "$" + dollarValue : "";
      } else if (item.costMethod == this.costTypeWeightedPercentage) {
        value = "";
      } else {
        if (percentageValue != "" && dollarValue != "") {
          value = "$" + dollarValue + ", " + percentageValue + "%";
        } else if (percentageValue != "") {
          value = percentageValue + "%";
        } else if (dollarValue != "") {
          value = "$" + dollarValue;
        }
      }
      return value;
    },
    addWeightedPercentage(i) {
      let obj = {
        weightValue: "",
        percentageValue: ""
      };
      this.accessorialsData[i].weightedPercentage.push(obj);
    },
    removeWeightedPercentage(mainIndex, subIndex) {
      if (this.accessorialsData[mainIndex].weightedPercentage.length > 1) {
        this.accessorialsData[mainIndex].weightedPercentage.splice(subIndex, 1);
      } else {
        this.$toast.error("Atleast one item required");
      }
    },
    toggleCollapse() {
      this.activeCollapse = !this.activeCollapse;
    }
  },
  mounted() {
    this.getSupportedAccessorials();
  }
};
</script>

<style>
.back-btn {
  color: #004225;
}

.edit-icon {
  cursor: pointer;
}

.edit-carrier-form {
  margin-left: 10%;
}

.edit-form-input .form-control {
  padding-left: 20px !important;
  box-shadow: 0px 1px 3px rgba(50, 50, 93, 0.5), 0px 1px 0px rgba(0, 0, 0, 0.1) !important;
}

.filter-option {
  box-shadow: 1px 1px 1px 1px rgb(50 50 93 / 50%), 0px 0px 0px rgb(0 0 0 / 10%) !important;
  height: fit-content;
}
.quantity-select-dropdown .choices__list--dropdown {
  position: relative !important;
}
</style>
