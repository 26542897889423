<template>
  <div>
    <div class="row mb-2">
      <div class="col-md-6">
        <span class="v-data-table-title"> {{ tableName }} </span>
      </div>
    </div>
    <div>
      <v-app id="inspire">
        <v-data-table
          v-model="selected"
          :headers="getTableHeaders()"
          :items="tableData"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          :single-select="singleSelect"
          :hide-default-footer="pagination ? false : true"
          item-key="quotenumber"
          sort-by="id"
          show-expand
          select-enable
          class="elevation-1"
          :items-per-page="pagination ? 25 : tableData.length"
        >

          <template v-slot:[`header.select`]="{ }">
            <div class="custom-control custom-checkbox">
              <input
                id="selectAll"
                type="checkbox"
                class="custom-control-input"
                v-model="selectAll"
                @click="multipleSelectQuote"
              />
              <label for="selectAll" class="custom-control-label"></label>
            </div>
          </template>

          <template v-slot:item.select="{ item }">
            <div class="checkbox-pin">
              <div class="custom-control custom-checkbox">
                <input
                  :id="item.quotenumber"
                  type="checkbox"
                  v-model="item.select"
                  class="custom-control-input"
                  @click="singleSelectQuote(item)"
                />
                <label :for="item.quotenumber" class="custom-control-label"></label>
              </div>
            </div>
          </template>

          <template v-slot:item.origin="{ item }">
            <span class="first-later-capital">
              {{ item.origin.input || item.origin }} to 
              {{ item.destination.input || item.destination }}
            </span>
          </template>
          
          <template v-slot:item.shipmendetails="{ item }">
            <div class="shipment-detail-lh">
              {{ item.shipmendetails }}
              <template>
                <i class="fa fa-info-circle info-icon" aria-hidden="true">
                  <vue-easy-tooltip
                    backgroundColor="#FFFFFF"
                    position="top"
                    :withArrow="true"
                    classes="shipment-item-tooltip"
                  >
                    <div class="row">
                      <div class="col-md-3 quantity-header">Quantity</div>
                      <div class="col-md-6">Dimensions</div>
                      <div class="col-md-3">Weight</div>
                    </div>
                    <div
                      class="row tooltip-body"
                      v-for="(shipmentItem, index) in item.details.items"
                      :key="index"
                    >
                      <div class="col-md-3 quantity-header">
                        {{ shipmentItem.quantity }}
                      </div>
                      <div class="col-md-6">
                        <span class="calculate-view">
                          <span>
                            {{ shipmentItem.length }}
                            <i class="fa fa-times" aria-hidden="true"></i>
                            <span class="lwh-text length-text">L</span>
                          </span>

                          <span>
                            {{ shipmentItem.width }}
                            <i class="fa fa-times" aria-hidden="true"></i>
                            <span class="lwh-text width-text">W</span>
                          </span>
                          <span class="last-height">
                            {{ shipmentItem.height }}
                            <span class="lwh-text height-text">H</span></span
                          >
                        </span>
                      </div>
                      <div class="col-md-3">
                        {{
                          shipmentItem.weight + " " + item.details.metric.weight
                        }}
                      </div>
                    </div>
                  </vue-easy-tooltip>
                </i>
              </template>
              <div>
                Weight: {{ item.details.totalWeight }}
                {{ item.details.metric.weight }}
              </div>
            </div>
          </template>

          <template v-slot:item.status="{ item }">
            <div v-if="item.status == quoteStatusList.DECLINED">
              <span class="badge mr-2 badge-danger badge-pill">
                Rejected by Client
              </span>
            </div>
            <div v-if="item.status == quoteStatusList.ACCEPTED">
              <span class="badge mr-2 badge-success badge-pill">
                Accepted by Client
              </span>
            </div>
            <div v-if="item.status == quoteStatusList.SAVED">
              <span class="badge mr-2 badge-default badge-pill">
                Saved Not sent
              </span>
            </div>
            <div v-if="item.status == quoteStatusList.UNDER_REVIEW">
              <span class="badge mr-2 badge-primary badge-pill">
                Under Review by Client
              </span>
            </div>
            <div v-if="item.status == quoteStatusList.SENT">
              <span class="badge mr-2 badge-info badge-pill sent-pill-btn">
                Sent
              </span>
            </div>
          </template>

          <template v-slot:item.createTemplate="{ item, index }">
            <base-button
              v-if="item.quoteItem.templateStatus"
              class="carriers-btn-text disabled-template-btn"
            >
              {{ item.createTemplate }}
              <vue-easy-tooltip
                backgroundColor="#FFFFFF"
                position="top"
                :withArrow="true"
                classes="shipment-item-tooltip"
              >
                This quote already exists as a template
              </vue-easy-tooltip>
            </base-button>
            <base-button
              v-else
              class="carriers-btn-text header-upgrade-btn"
              @click="createNewTemplate(index, item)"
            >
              {{ item.createTemplate }}
            </base-button>
          </template>

          <template v-slot:item.action="{ item }">
            <base-button
              type="primary"
              class="btn-theme-EFEBEB action-left"
              @click="showQuoteResult(item)"
            >
              <img src="../../assets/img/home/edit.png"/>
            </base-button>
            <base-button
              type="primary"
              class="btn-theme-EFEBEB action-right"
              @click="showModal(item)"
            >
              <img src="../../assets/img/home/eye.png"/>
            </base-button>
          </template>

          <!-- Expanded items -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-simple-table>
                <template v-slot:default>
                  <quotes-result-table
                    tableName="best-quotes"
                    :tableData="bestQuotes"
                    :selectedTab="bestSelectedTab"
                    :isQuotesTable="true"
                    @set-best-quotes-from-rates="setBestQuotesFromRates(item)"
                    @handle-margin="handleMargin"
                  />
                </template>
              </v-simple-table>
            </td>
          </template>
        </v-data-table>
      </v-app>
    </div>

    <modal
      :show.sync="templateModal"
      modal-classes="modal-dialog-centered invite-modal"
    >
      <h5
        slot="header"
        class="modal-title confrim-quote-header"
        id="modal-title-default"
      >
        Create a template
      </h5>
      <div>
        <base-input
          type="text"
          name="Name"
          placeholder="Name"
          v-model="templateName"
          @input="(event) => changeTemplateName(event)"
        ></base-input>
        <span v-if="!templateNameIsValid" class="validate-error"
          >Name is required</span
        >
      </div>
      <div class="mt-3 text-right">
        <base-button
          type="primary"
          class="pallet-theme-btn"
          @click="saveTemplate"
        >
          Save
        </base-button>
      </div>
    </modal>

    <quote-preview-modal
      ref="quotePreviewModal"
      @show-quote-result="showQuoteResult"
    />
  </div>
</template>

<script>
import VueEasyTooltip from "vue-easy-tooltip";
import QuotePreviewModal from "@/views/quotes/QuotePreviewModal.vue";
import Modal from "@/components/Modal.vue";
import { mapGetters } from "vuex";
import {
  quoteStatus,
  firstCharCapitalize,
} from "@/helpers/utility";
import { accessorials } from "@/helpers/accessorials";
import { API, masterAPI } from "@/api/API";
import QuotesResultTable from "@/views/quotes/QuotesResultTable.vue";
import { checkEmptyValue } from "@/helpers/utility";

export default {
  name: "light-table",
  // List of imported components
  components: {
    VueEasyTooltip,
    QuotePreviewModal,
    Modal,
    QuotesResultTable
  },
  props: {
    // Component props
    tableName: {
      type: String,
    },
    pagination: {
      type: Boolean,
    },
    tableData: {
      type: Array,
    },
  },
  data() {
    return {
      // Component's data properties
      singleSelect: false,
      selected: [],
      expanded: [],
      singleExpand: false,
      headers: [
        // Table headers configuration
        { text: "Select", value: "select", sortable: false },
        { text: "Quote Number", value: "quotenumber", sortable: false },
        { text: "Client Name", value: "clientName", sortable: false },
        // { text: "Carrier Name", value: "carrierName", sortable: false },
        { text: "Origin & Destination", value: "origin", sortable: false },
        // { text: "Created By", value: "createdBy", sortable: false },
        // { text: "Shipment Details", value: "shipmendetails", sortable: false },
        { text: "Status", value: "status", width: "200px", sortable: false },
        {
          text: "Created On",
          value: "createdAt",
          width: "125px",
          sortable: false,
        },
        // { text: "Create a template from this quote", value: "createTemplate",width:"50px",sortable: false},
        { text: "Actions", value: "action", sortable: false , width: "150px"},
        { text: "", value: "data-table-expand", class: "custom-th-class" },
      ],
      quoteStatusList: quoteStatus,
      templateModal: false,
      createTemplateIndex: 0,
      templateName: "",
      templateNameIsValid: true,
      templateStatus: "",
      selectedQuoteItem: {},
      selectAll: false,
      selectedQuote: [],
      bestQuotes: [],
      bestSelectedTab: false,
    };
  },
  computed: {
    ...mapGetters(["client","userIsBroker"]),
  },
  methods: {
    /**
     * Show the quote result page for a given item.
     * @param {Object} item - Quote item
     */
    showQuoteResult(item) {
      let accessorialsDetail = {};

      // If `rates` is an array, iterate or pick the specific rate object
      if (Array.isArray(item.rates)) {
        // Find the rate that contains the accessorialsCost (assuming you need to check the first or specific rate)
        const rate = item.rates.find(rate => rate.accessorialsCost);

        if (rate && rate.accessorialsCost.accessorials) {
          accessorialsDetail = accessorials(rate.accessorialsCost.accessorials);
        }
      }
      this.$session.remove("save_quote_details");
      let quoteDetail = {
        origin: firstCharCapitalize(item.origin.input || item.origin),
        destination: firstCharCapitalize(item.destination.input || item.destination),
        addShipmentItem: item.details.items,
        selectedTab: item.details.metric.weight,
        response: item.details,
        pickupAccessorials: accessorialsDetail.pickupAccessorialItem,
        deliveryAccessorials: accessorialsDetail.deliveryAccessorialItem,
        otherAccessorials: accessorialsDetail.othersAccessorialItem,
        isEdit: true
      };

      if (this.userIsBroker) {
        let clientName = this.client
        .map((items) => {
          if (items.id == item.clientId) {
            return items;
          }
        })
        .filter(function(element) {
          return element !== undefined;
        });
                
        let margin = item.details.margin;
        quoteDetail.clientName = clientName.length > 0 ? clientName[0] : "";
        quoteDetail.quoteContact = [item.details.contact ? item.details.contact.name : ""];
        quoteDetail.margin = margin ? margin.value : "";
        quoteDetail.marginDollar = margin ? margin.type == "fixed" ? true : false : false;
      }
      
      this.$session.set("save_quote_details", JSON.stringify(quoteDetail));

      this.$router
        .push({ path: "/quote-result", name: "quote-result" })
        .catch(() => {});
    },
     /**
     * Create a new template from a quote item.
     * @param {number} index - Index of the quote item
     * @param {Object} item - Quote item
     */
    createNewTemplate(index, item) {
      this.templateName = "";
      this.templateModal = true;
      this.createTemplateIndex = index;
      this.templateStatus = item.status;
      this.selectedQuoteItem = item.quoteItem;
    },
    /**
     * Validate the template name on change.
     * @param {string} value - Template name value
     */
    changeTemplateName(value) {
      if (value == "") {
        this.templateNameIsValid = false;
      } else {
        this.templateNameIsValid = true;
      }
    },
    /**
     * Save the template.
     */
    async saveTemplate() {
      if (this.templateName != "") {
        let bodyPayload = JSON.stringify({
          name: this.templateName,
          quoteId: this.selectedQuoteItem.id,
          metadata: this.selectedQuoteItem,
        });

        let response = await masterAPI(
          API.API_ENDPOINT.createTemplate,
          API.API_METHOD.post,
          undefined,
          bodyPayload
        );

        if (response.status == 200) {
          this.templateModal = false;
          this.templateNameIsValid = true;
          this.$toast.success("Template create successful", {
            timeout: 1000,
          });
          this.$emit("get-all-quote");
        } else {
          this.templateModal = false;
          this.templateNameIsValid = true;
        }
      } else {
        this.templateNameIsValid = false;
      }
    },
    /**
     * Gets the table headers based on user mode.
     *
     * @returns {Array} An array of table headers based on user mode.
    */
    getTableHeaders() {
      return this.headers.filter((header) => {
        // Keep existing logic for hiding "clientName" if the user is not a broker
        if (!this.userIsBroker && header.value === "clientName") {
          return false;
        }
        // Hide "status" column when tableName is NOT "All Quotes"
        if (header.value === "status" && this.tableName == "All Quotes") {
          return false;
        }
        return true;
      });
    },
    /**
     * Opens a modal with the provided item's details.
     * @param {Object} item - The item to display in the modal.
    */
    showModal(item) {
      this.$refs.quotePreviewModal.openModal(item);
    },
    /**
     * Toggles the selection state of a single quote.
     * @param {Object} selectedItem - The selected item.
    */
    singleSelectQuote(selectedItem) {
      const selectedIndex = this.tableData.findIndex(item => item.quotenumber === selectedItem.quotenumber);
      this.tableData[selectedIndex].select = !selectedItem.select;
      const selectedItems = this.tableData.filter(item => item.select);
      this.selectedQuote = selectedItems;
    },
    /**
     * Toggles the selection state of all quotes.
    */
    multipleSelectQuote() {
      this.selectAll = !this.selectAll;

      this.tableData.forEach(item => {
        item.select = this.selectAll;
      });

      let checkboxElement = document.querySelectorAll('.single-checkbox');

      checkboxElement.forEach(item => {
        item.checked = this.selectAll;
      });

      const selectedItems = this.tableData.filter(item => item.select);
      this.selectedQuote = selectedItems;
    },
    /**
     * Updates the margin values for the quote records based on the selected options.
     * @param {boolean} isDollar - Indicates whether the margin should be in dollar value.
     * @param {string} isTable - Specifies the table type ('best-quotes').
     */
    handleMargin (isDollar, isTable) {
      let quoteRecord = [];
      if (isTable == "best-quotes") {
        quoteRecord = this.bestQuotes;
        if (isDollar) {
          this.bestSelectedTab = true;
        } else {
          this.bestSelectedTab = false;
        }
      }
      for (let i = 0; i < quoteRecord.length; i++) {
        let currentMargin = quoteRecord[i].rates.marginCost;
        let margin = isDollar
          ? currentMargin.dollarValue
          : currentMargin.percentageValue;
        // Use Vue's $set to update margin and ensure reactivity
        this.$set(this.bestQuotes, i, {
          ...this.bestQuotes[i], // Spread the current data
          margin: margin      // Update the margin
        });
        this.bestQuotes[i].margin = margin;
      }
    },
    /**
     * Method to update the best quotes from the given rates
     * @param {Object} item - The item containing the rates
     */
    setBestQuotesFromRates(item) {
      this.bestQuotes = [];
      // Iterate over item.rates and modify each rate
      item.rates.forEach((rate, i) => {
        let currentMargin = rate.marginCost ?? {};  // Fallback to empty object if marginCost doesn't exist
        let margin = currentMargin?.type === "fixed"
          ? currentMargin.dollarValue
          : currentMargin?.percentageValue;

        let fuelSurcharge = rate.additionalsCost?.additionals ?? [];
        let accessorialsCost = rate.accessorialsCost ?? [];

        let totalCost = rate.totalCost;
        let carrierName = rate.lane?.carrier?.name || "---";
        let carrierRate = rate.totalCostBeforeMargin;
        let publicRate = rate.public || false;

        let rateObj = {
          name: i,
          carrier: carrierName,
          serviceLevel: "---",
          transitTime: "---",
          carrierRate: carrierRate,
          quotedRate: totalCost ? checkEmptyValue(totalCost) : totalCost,
          baseCost: rate.baseCost,
          margin: margin,
          id: i,
          isBestQuotes: i < 3 ? true : false,
          rates: rate,
          currentMargin: currentMargin,
          fuelSurcharge: fuelSurcharge,
          accessorialsCost: accessorialsCost,
          subTotal: rate.totalCostBeforeMargin,
          total: totalCost ? checkEmptyValue(totalCost) : totalCost,
          select: false,
          baseRate: rate.baseRate,
          currencyType: rate.currencyType || "",
          carrierName: carrierName,
          buyRate: carrierRate,
          publicRate
        };
        this.bestQuotes.push(rateObj);
      });
      // this.bestSelectedTab = item.marginDollar;
      return this.bestQuotes;
    },
  },
};
</script>

<style></style>
