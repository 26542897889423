<template>
  <div>
    <div class="row v-table-row expanded-row">
      <div class="col-md-3">
        <p class="accessorials-title">
          Quote Number
        </p>
        <div class="best-quotes-item">
          <a
            v-if="item.rates.quoteLink"
            :href="`${item.rates.quoteLink}`"
            target="_blank"
            >{{ item.rates.quoteNumber }}</a
          >
          <div v-else>{{ item.rates.quoteNumber }}</div>
        </div>
      </div>
      <div class="col-md-2">
        <p class="accessorials-title">Line Item</p>
        <div class="best-quotes-item">
          Chargeable Rate
        </div>
      </div>
      <div class="col-md-3">
        <p class="accessorials-title">Chargeable Weight</p>
        <div class="best-quotes-item">
          <div v-if="item.rates.deficitWeightApplied">
            <span>{{ item.rates.deficitWeight }} (deficit)</span><br />
            <span>{{ item.rates.totalChargeableWeight }} (original)</span>
          </div>
          <div v-else>
            {{ item.rates.totalChargeableWeight }}
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <p class="accessorials-title">Base Rate</p>
        <div class="best-quotes-item">
          {{ displayCurrencyValue(item.baseRate, item.currencyType) }}
        </div>
      </div>
      <div class="col-md-2">
        <p class="accessorials-title">Charge</p>
        <div class="best-quotes-item">
          {{ displayCurrencyValue(item.rates.baseCost, item.currencyType) }}
        </div>
      </div>
    </div>
    <div
      v-if="
        item.rates.deficitChargeableWeight &&
          item.rates.deficitChargeableWeight !==
            item.rates.totalChargeableWeight
      "
      class="row v-table-row"
    >
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <div class="best-quotes-item">
          Actual Chargeable Weight
        </div>
      </div>
      <div class="col-md-2">
        <div class="best-quotes-item">
          {{ item.rates.totalChargeableWeight }}
        </div>
      </div>
    </div>

    <!-- Accessorials -->
    <div class="expanded-row">
      <div class="row v-table-row mb-2">
        <div class="col-md-3">
          <p class="accessorials-title">
            Accessorials:
          </p>
        </div>
        <div class="col-md-3">
          <div
            v-for="(accessorial, index) in item.rates.accessorialsCost
              .accessorials"
            :key="index"
          >
            <div class="best-quotes-item">
              {{ accessorial.name }}
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-2"></div>
        <div class="col-md-2">
          <div
            v-for="(accessorial, index) in item.rates.accessorialsCost
              .accessorials"
            :key="index"
          >
            <div class="best-quotes-item">
              {{ displayCurrencyValue(accessorial.cost, item.currencyType) }}
            </div>
          </div>
        </div>
      </div>
      <!-- Fuel surchage -->
      <div
        class="row v-table-row mb-2"
        v-for="(result, index) in item.rates.additionalsCost.additionals"
        :key="index"
      >
        <div class="col-md-3"></div>
        <div class="col-md-3">
          {{ result.name }}
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-2"></div>
        <div class="col-md-2">
          {{ displayCurrencyValue(result.cost, item.currencyType) }}
        </div>
      </div>
    </div>

    <!-- Subtotal -->
    <div v-if="userIsBroker" class="row v-table-row">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <p class="accessorials-title">Subtotal</p>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-2"></div>
      <div class="col-md-2">
        <p class="accessorials-title">
          {{
            displayCurrencyValue(
              item.rates.totalCostBeforeMargin,
              item.currencyType,
            )
          }}
        </p>
      </div>
    </div>
    <!-- Broker margin -->
    <div v-if="userIsBroker" class="row v-table-row mb-3">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        Margin
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-2"></div>
      <div class="col-md-2">
        {{
          displayCurrencyValue(item.rates.marginCost.cost, item.currencyType)
        }}
      </div>
    </div>
    <!-- Taxes -->
    <div v-if="item.rates.taxesCost">
      <div
        class="row v-table-row mb-2"
        v-for="(result, index) in item.rates.taxesCost.taxes"
        :key="index"
      >
        <div class="col-md-3"></div>
        <div class="col-md-3">
          {{ result.name }}
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-2"></div>
        <div class="col-md-2">
          {{ displayCurrencyValue(result.cost, item.currencyType) }}
        </div>
      </div>
    </div>
    <div class="row v-table-row">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <p class="accessorials-title">Total</p>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-2"></div>
      <div class="col-md-2">
        <p class="accessorials-title">
          {{ displayCurrencyValue(item.total, item.currencyType) }}
        </p>
      </div>
    </div>
    <div v-if="item.terminals?.origin" class="row v-table-row terminals">
      <div class="col-md-2"></div>
      <div class="col-md-3">
        <p class="terminal-title">Origin Terminal</p>
        <p v-if="item.terminals.origin.name" class="terminal-field">
          {{ item.terminals.origin.name }}
        </p>
        <p class="terminal-field">{{ item.terminals.origin.address1 }}</p>
        <p v-if="item.terminals.origin.address2" class="terminal-field">
          {{ item.terminals.origin.address2 }}
        </p>
        <p class="terminal-field">{{ item.terminals.origin.city }}</p>
        <p class="terminal-field">{{ item.terminals.origin.state }}</p>
        <p class="terminal-field">{{ item.terminals.origin.zip }}</p>
        <p v-if="item.terminals.origin.phone" class="terminal-field">
          Tel: {{ item.terminals.origin.phone }}
        </p>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-3">
        <p class="terminal-title">Destination Terminal</p>
        <p v-if="item.terminals.destination.name" class="terminal-field">
          {{ item.terminals.destination.name }}
        </p>
        <p class="terminal-field">{{ item.terminals.destination.address1 }}</p>
        <p v-if="item.terminals.destination.address2" class="terminal-field">
          {{ item.terminals.destination.address2 }}
        </p>
        <p class="terminal-field">{{ item.terminals.destination.city }}</p>
        <p class="terminal-field">{{ item.terminals.destination.state }}</p>
        <p class="terminal-field">{{ item.terminals.destination.zip }}</p>
        <p v-if="item.terminals.destination.phone" class="terminal-field">
          Tel: {{ item.terminals.destination.phone }}
        </p>
      </div>
    </div>
    <!--    <div class="row v-table-row">-->
    <!--      <div class="col-md-3"></div>-->
    <!--      <div class="col-md-3">-->
    <!--        <p class="accessorials-title">Excel Cost</p>-->
    <!--      </div>-->
    <!--      <div class="col-md-2"></div>-->
    <!--      <div class="col-md-2"></div>-->
    <!--      <div class="col-md-2">-->
    <!--        <p class="accessorials-title">-->
    <!--          {{ displayCurrencyValue(item.rates.excelCost, item.currencyType) }}-->
    <!--        </p>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { clearCurrencyValue } from "@/helpers/utility";

export default {
  name: "light-table",
  components: {},
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["userIsBroker"]),
  },
  methods: {
    /**
     * Displays the currency value based on the specified currency type.
     * @param {number} currencyValue - The value of the currency.
     * @param {string} currencyType - The type of the currency (e.g., USD, CDN).
     * @returns {number} - The cleared currency value.
     */
    displayCurrencyValue(currencyValue, currencyType) {
      return clearCurrencyValue(currencyValue, currencyType);
    },
  },
};
</script>

<style>
.terminals {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #f4f4f4;
}
.terminal-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 27px;
  color: #6f767e;
  margin-bottom: 2px;
}
.terminal-field {
  font-size: 13px;
  margin-bottom: 2px;
}
</style>
