import { API, masterAPI } from "@/api/API";
import moment from "moment";

/**
 * Quote status constants.
 */
export const quoteStatus = {
  SAVED: "saved",
  SENT: "sent",
  UNDER_REVIEW: "underReview",
  ACCEPTED: "accepted",
  DECLINED: "declined",
};

/**
 * Currency type constants.
 */
export const currencyTypeItems = {
  USD: "USD",
  CDN: "CDN",
};

/**
 * User types constants.
 */
export const userTypes = {
  ADMIN: "admin",
  MEMBER: "member",
};

/**
 * User mode constants.
 */
export const userModes = {
  BROKER: "broker",
  SHIPPER: "shipper",
};

/**
 * Section list constants.
 */
export const sectionList = {
  lane: "Lane",
  accessorial: "Accessorial",
  fuelSurcharge: "Fuel Surcharge",
  conditions: "Condition",
  customerOrCustomerGroup: "Customer or Customer Group",
  classBaseRate: "Class - Base Rate",
};

/**
 * Data type constants.
 */
export const dataType = {
  fixed: "Fixed",
  percentage: "Percentage",
  fixedPlusPercentage: "Fixed + Percentage",
  perhundred: "Perhundred",
  percentageOfFreightCharges: "Percentage of Freight Charges",
};

/**
 * Converts inches to centimeters.
 * @param {number} value - The value in inches.
 * @returns {number} - The converted value in centimeters.
 */
export function convertInchToCentimeter(value) {
  let incToCms = value != "" ? parseFloat(value * 2.54) : value;
  return incToCms;
}

/**
 * Converts centimeters to inches.
 * @param {number} value - The value in centimeters.
 * @returns {number} - The converted value in inches.
 */
export function convertCentimeterToInch(value) {
  let cmsToIns = value != "" ? parseFloat(value / 2.54) : value;
  return cmsToIns;
}

/**
 * Converts pounds to kilograms.
 * @param {number} value - The value in pounds.
 * @returns {number} - The converted value in kilograms.
 */
export function convertLbsToKgs(value) {
  let lbsTokgs = value != "" ? parseFloat(value / 2.205) : value;
  return lbsTokgs;
}

/**
 * Converts kilograms to pounds.
 * @param {number} value - The value in kilograms.
 * @returns {number} - The converted value in pounds.
 */
export function convertKgsToLbs(value) {
  let kgsToLbs = value != "" ? parseFloat(value * 2.205) : value;
  return kgsToLbs;
}

/**
 * Converts a dollar value to a percentage based on the margin and buy rate.
 * @param {number} margin - The margin value.
 * @param {number} buyRate - The buy rate value.
 * @returns {number} - The converted percentage value.
 */
export function convertDollarToPercentage(margin, buyRate) {
  let percentage = (margin * 100) / buyRate;
  let dollarToPercentage = parseFloat(percentage).toFixed(2);
  return dollarToPercentage;
}

/**
 * Clears the float value by removing decimal points and trailing zeros.
 * @param {number} value - The value to be cleared.
 * @returns {string} - The cleared value.
 */
export function clearFloatValue(value) {
  let item;
  if (value.includes(".00")) {
    let splitItem = value.split(".00");
    item = splitItem[0];
  } else {
    item = value;
  }
  return item;
}

/**
 * Checks if the value is empty and clears it if not empty.
 * @param {string} value - The value to be checked.
 * @returns {string} - The checked and cleared value.
 */
export function checkEmptyValue(value) {
  let item;
  if (value !== "" && value != null && !isNaN(value)) {
    item = clearFloatValue(value.toFixed(2));
  } else {
    item = value;
  }
  return item;
}

/**
 * Capitalizes the first character of a string.
 * @param {string} s - The string to be capitalized.
 * @returns {string} - The capitalized string.
 */
export function firstCharCapitalize(s) {
  return s && s[0].toUpperCase() + s.slice(1);
}

/**
 * Gets the base rate from a rate object.
 * @param {object} rate - The rate object.
 * @returns {string} - The base rate value.
 */
export function getBaseRate(rate) {
  let baseRate = rate.baseRate;
  return checkEmptyValue(baseRate);
}

/**
 * Clears the currency value by adding the currency symbol.
 * @param {number} value - The currency value.
 * @param {string} currencyType - The currency type.
 * @returns {string} - The cleared currency value.
 */
export function clearCurrencyValue(value, currencyType) {
  if (value && (value === "0" || currencyType === 0)) {
    return "Included";
  }

  let currencyValue = "";
  if (value != null) {
    currencyValue =
      currencyType == currencyTypeItems.CDN ? "C$" + value : "$" + value;
  }
  return currencyValue;
}

/**
 * Sorts an array of data by the modified date in descending order.
 * @param {Array} data - The data to be sorted.
 * @returns {Array} - The sorted data.
 */
export function sortByModifiedDate(data) {
  let sortedList = data.slice().sort((r1, r2) => {
    return dateSortFunction(r1.updatedAt, r2.updatedAt);
  });
  return sortedList;
}

/**
 * Sorts an array of data by date in descending order.
 * @param {string} dateString1 - The first date string.
 * @param {string} dateString2 - The second date string.
 * @returns {number} - The sort order.
 */
export function dateSortFunction(dateString1, dateString2) {
  let d1 = moment(dateString1)
      .toDate()
      .getTime(),
    d2 = moment(dateString2)
      .toDate()
      .getTime();
  if (isNaN(d1)) {
    return isNaN(d2) ? 0 : 1;
  } else if (isNaN(d2)) {
    return isNaN(d1) ? 0 : -1;
  } else {
    return d1 > d2 ? -1 : d1 < d2 ? 1 : 0;
  }
}

/**
 * Gets the name of the user who created the quote.
 * @param {Array} users - The list of users.
 * @param {string} userId - The ID of the user.
 * @returns {string} - The name of the user who created the quote.
 */
export function getCreatedQuoteUserName(users, userId) {
  let quoteUserName = users
    .map(item => {
      if (item.id == userId) {
        return item;
      }
    })
    .filter(function(element) {
      return element !== undefined;
    });

  return quoteUserName.length > 0 ? quoteUserName[0].name : "";
}

/**
 * Formats the quote number by padding it with zeros.
 * @param {number} quoteNumber - The quote number.
 * @returns {string} - The formatted quote number.
 */
export function getQuoteNumber(quoteNumber) {
  return quoteNumber.toString().padStart(6, "0");
}

/**
 * Sorts an array of data by the created date in descending order.
 * @param {Array} data - The data to be sorted.
 * @returns {Array} - The sorted data.
 */
export function sortByCreatedAtDateDesc(data) {
  let sortedList = data.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at),
  );
  return sortedList;
}

/**
 * Sorts an array of data by the created date in ascending order.
 * @param {Array} data - The data to be sorted.
 * @returns {Array} - The sorted data.
 */
export function sortByCreatedAtDateAsce(data) {
  let sortedList = data.sort(
    (a, b) => new Date(a.created_at) - new Date(b.created_at),
  );
  return sortedList;
}

/**
 * Sets the password in local storage.
 * @param {string} password - The password to be set.
 */
export function setPassword(password) {
  let encodePassword = Buffer.from(password).toString("base64");
  localStorage.setItem("p_key", encodePassword);
}

/**
 * Retrieves the password from local storage.
 * @returns {string} - The decoded password.
 */
export function getPassword() {
  let password = localStorage.getItem("p_key");
  let decodedPassword = "";
  if (password) {
    decodedPassword = Buffer.from(password, "base64").toString("ascii");
  }
  return decodedPassword;
}

/**
 * Checks for empty shipment items and returns the non-empty items.
 * @param {Array} addShipmentItem - The shipment items to be checked.
 * @returns {Array} - The non-empty shipment items.
 */
export function checkEmptyShipmentItem(addShipmentItem) {
  let shipmentItem = [];
  if (addShipmentItem.length > 0) {
    let listItem = addShipmentItem
      .map(item => {
        if (
          item.height != "" &&
          item.length != "" &&
          item.weight != "" &&
          item.width != "" &&
          item.quantity != "" &&
          item.quantity != 0
        ) {
          return item;
        }
      })
      .filter(function(element) {
        return element !== undefined;
      });

    if (listItem.length > 0) {
      shipmentItem = listItem;
    } else {
      shipmentItem = [];
    }
  }
  return shipmentItem;
}

/**
 * Sets the client list with formatted data.
 * @param {Array} listClient - The list of clients.
 * @returns {Array} - The formatted client list.
 */
export function setClientList(listClient) {
  let clientList = [];
  for (const client of listClient) {
    let updatedAt = client.updatedAt;
    let clientDetail = {
      select: false,
      name: client.name,
      updatedAt: moment(updatedAt).format("MMM Do, YYYY"),
      margin: client.margin,
      edit: "",
      id: client.id,
      userId: client.userId,
      contacts: client.contacts,
      currencyType: client.currencyType,
    };

    clientList.push(clientDetail);
  }
  return clientList;
}

export function getContactByCheckName(selectedContact, clientContact) {
  let contact = [];
  selectedContact.map(item => {
    clientContact.map(contactsObj => {
      if (item == contactsObj.name) {
        contact.push(contactsObj);
      }
    });
  });
  let contactObj = {};
  if (contact.length != 0) {
    contactObj = contact[0];
  }
  return contactObj;
}

/**
 * Formats a date string using the "MMM Do, YYYY" format.
 * @param {string} date - The date string to be formatted.
 *
 * @returns {string} - The formatted date string.
 */
export function setDateFormat(date) {
  return moment(date).format("MMM Do, YYYY");
}

/**
 * Formats a date to 'YYYY-MM-DD HH:mm:ss' format.
 * @param {string | Date} date - The date to be formatted.
 * @returns {string} - The formatted date in 'YYYY-MM-DD HH:mm:ss' format.
 */
export function formatToLocalDate(date) {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
}

/**
 * Formats a date to 'YYYY-MM-DD HH:mm:ss' format, ensuring it includes time.
 *
 * @param {string | Date} date - The input date to be formatted.
 * @returns {string} - The formatted date and time in 'YYYY-MM-DD HH:mm:ss' format.
 */
export function formatDateWithTime(date) {
  const parsedDate = moment(date, moment.ISO_8601, true).isValid()
    ? moment(date)
    : moment(date).startOf("day"); // Default to start of the day if invalid

  // Explicitly add the current time if no time exists
  if (parsedDate.format("HH:mm:ss") === "00:00:00") {
    parsedDate.set({
      hour: moment().hour(),
      minute: moment().minute(),
      second: moment().second()
    });
  }

  return parsedDate.format("YYYY-MM-DD HH:mm:ss");
}

/**
 * Gets the method key by item object from a list of dropdown options.
 * @param {Array} list - List of dropdown options.
 * @param {string} key - Key to search for.
 * @returns {Object|null} - The item detail object or null if not found.
 */
export function getMethodKeyByItemObject(list, key) {
  const foundItem = list.find(item => item.itemDetail.key === key);
  return foundItem ? foundItem.itemDetail : null;
}

/**
 * Fetches account module data from a specified API endpoint.
 * @param {string} apiEndPoint - The API endpoint to fetch data from.
 * @returns {Promise<Array>} - A promise that resolves to an array of dropdown options.
 */
export async function accountModuleApi(apiEndPoint) {
  let response = await masterAPI(
    apiEndPoint,
    API.API_METHOD.get,
    undefined,
    undefined,
  );

  if (response.status == 200) {
    return createDropdownOption(response.data);
  } else {
    return [];
  }
}

/**
 * Creates dropdown options from a list of items.
 * @param {Array} list - List of items to create dropdown options from.
 * @returns {Array} - An array of dropdown options with id, text, and itemDetail properties.
 */
export function createDropdownOption(list) {
  let optionList = [];
  for (const [index, item] of list.entries()) {
    optionList.push({
      id: index,
      text: item.name,
      itemDetail: item
    });
  }
  return optionList;
}

/**
 * Object defining the number of items per page for pagination in different tables.
 */
export const tablePaginationPerPageItem = {
  customers: 5,
  customerGroups: 5,
};

/** Currencies based on company headquarters locations.
 * Currently not used.*/
export const carrierHeadquarters = {
  fastfrate: "CAD",
  overlandWest: "CAD",
  comox: "CAD",
  vitran: "CAD",
  cheetah: "CAD",
  aerostream: "CAD",
  aeropacific: "CAD",
  messagers: "CAD",
  dayAndRoss: "CAD",
  arcBest: "USD",
  manitoulin: "CAD",
  estes: "USD",
  csa: "CAD",
  polaris: "CAD",
};

/**
 * Return the currency for the given rate.
 * @param {object} rate The rate to get the currency for.
 * @returns {string} CAD, USD, or MXN.
 */
export function determineCurrency(rate) {
  let currency = rate.currency;
  if (currency) {
    // If the currency type is provided in the rate, we just use that.
    return currency;
  }
  const { destination, origin } = rate.lane;
  const destCountry = destination.split(" ").pop();
  const originCountry = origin.split(" ").pop();
  if (
    destCountry.toLowerCase() === "canada" &&
    originCountry.toLowerCase() === "canada"
  ) {
    currency = "CAD";
  } else if (
    destCountry.toLowerCase() === "usa" &&
    originCountry.toLowerCase() === "usa"
  ) {
    currency = "USD";
  } else if (
    destCountry.toLowerCase() === "mexico" &&
    originCountry.toLowerCase() === "mexico"
  ) {
    currency = "MXN";
  } else {
    currency = "---";
  }
  return currency;
};

/**
 * Creates a debounced function that delays execution of `func` until `timeout` ms have passed since the last call.
 * @param {(...args: any[]) => Promise<void> | void} fn - The function to debounce.
 * @param {number} [timeout=500] - The debounce delay in milliseconds.
 * @returns {(...args: any[]) => void} A debounced function.
 */
export function debounce(fn, timeout = 500) {
  let timer;
  return function(...args) {
    clearTimeout(timer);
    const that = this;
    timer = setTimeout(function() {
      fn.apply(that, args);
    }, timeout);
  };
}
