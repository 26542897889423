<template>
  <div>
    <img class="shipment-search-icon" src="../../assets/img/search.png">
    <vue-input
      alternative
      placeholder="Search quotes"
      type="text"
      class="search-all-quote"
      v-model="searchQuote"
      @input="(event) => searchAllQuotesByClient(event)"
    ></vue-input>
  </div>
</template>

<script>

export default {
  name: "light-table",
  components: {
  },
  props: {
    searchData: {
      type: Array,
    },
    tableName: {
      type: String,
    },
  },
  data() {
    return {
      searchQuote: "", // Stores the search query
    };
  },
  watch: {
    tableName: {
      immediate: true,
      handler() {
        this.searchQuote = ""; // Reset search input when tableName changes
        this.$emit("search-quotes", this.searchData); // Reset table data to original
      }
    }
  },
  methods: {
    /**
     * Searches quotes based on the client name.
     * @param {String} searchValue - The search query entered by the user.
     */
    searchAllQuotesByClient(searchValue) {
      let searchItem = this.searchData.map((filterItem) => {
        let originInput = typeof filterItem.origin === "object" ? filterItem.origin.input : filterItem.origin;
        let destinationInput = typeof filterItem.destination === "object" ? filterItem.destination.input : filterItem.destination;

        if (
          filterItem.quotenumber.toLowerCase().includes(searchValue.toLowerCase()) ||
          filterItem.clientName.toLowerCase().includes(searchValue.toLowerCase()) ||
          (originInput && originInput.toLowerCase().includes(searchValue.toLowerCase())) ||
          (destinationInput && destinationInput.toLowerCase().includes(searchValue.toLowerCase()))
        ) {
          return filterItem;
        }
      }).filter(function(element) {
        return element !== undefined;
      });
      
      // Emit an event with the filtered search results
      this.$emit("search-quotes", searchItem);
    },
  },
};
</script>

<style></style>
