<template>
  <div class="wrapper">
    <div class="body-container row">
      <left-panel :activeMenuName="activeMenuName"></left-panel>
      <loader :isLoader="showLoader"></loader>
      <div class="col-lg-10 right-panel-scroll plr-30 mt-4">
        <div class="row right-panel-header">
          <div class="col-md-4">
            <search-bar />
          </div>
          <div class="col-md-4"></div>
          <div class="col-md-4">
            <profile-header />
          </div>
        </div>

        <page-heading-card
          title="All Quotes"
          subTitle=""
          buttonName="Create Quote"
          imageName="quote.png"
          addRecordScreenName="newquote"
        />

        <div class="mt-5 mb-4 section-b">
          <div class="row search-quotes-padding">
            <div class="col-md-5 mt-1">
              <ul
                role="tablist"
                class="custom-tabs nav nav-pills-info nav-pills btn-group quote-tab"
              >
                <li
                  data-toggle="tab"
                  role="tablist"
                  aria-expanded="true"
                  class="nav-item active"
                >
                  <a
                    data-toggle="tab"
                    role="tablist"
                    class="nav-link"
                    :aria-expanded="selectedQuoteTab ? 'true' : 'false'"
                    :class="selectedQuoteTab ? 'active' : ''"
                    @click.prevent="handleQuoteTab(true)"
                  >
                    <div>{{ myQuotesText }}</div>
                  </a>
                </li>
                <li
                  data-toggle="tab"
                  role="tablist"
                  aria-expanded="true"
                  class="nav-item active"
                >
                  <a
                    data-toggle="tab"
                    role="tablist"
                    class="nav-link "
                    :aria-expanded="!selectedQuoteTab ? 'true' : 'false'"
                    :class="!selectedQuoteTab ? 'active' : ''"
                    @click.prevent="handleQuoteTab(false)"
                  >
                    <div>{{ teamQuotesText }}</div>
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-6"></div>
          </div>

          <div class="mt-5">
            <base-button
              class="btn-theme-083C2F q-btn"
              @click="
                filterQuote(quoteTitleList.allQuotes, quoteTitleList.allQuotes)
              "
            >
              {{ quoteTitleList.allQuotes }}
              <span>
                {{ dbQuotesRequestsList.length }}
              </span>
            </base-button>

            <base-button
              class="btn-theme-905CFF q-btn"
              @click="
                filterQuote(quoteStatusList.SAVED, quoteTitleList.savedQuotes)
              "
            >
              {{ quoteTitleList.savedQuotes }}
              <span>
                {{ getQuoteLengthByStatus(quoteStatusList.SAVED) }}
              </span>
            </base-button>

            <base-button
              class="btn-theme-009F70 q-btn"
              @click="
                filterQuote(
                  quoteStatusList.ACCEPTED,
                  quoteTitleList.acceptedQuotes
                )
              "
            >
              {{ quoteTitleList.acceptedQuotes }}
              <span>
                {{ getQuoteLengthByStatus(quoteStatusList.ACCEPTED) }}
              </span>
            </base-button>

            <base-button
              class="btn-theme-F1C40F q-btn"
              @click="
                filterQuote(
                  quoteStatusList.UNDER_REVIEW,
                  quoteTitleList.underReviewByClient
                )
              "
            >
              {{ quoteTitleList.underReviewByClient }}
              <span>
                {{ getQuoteLengthByStatus(quoteStatusList.UNDER_REVIEW) }}
              </span>
            </base-button>

            <base-button class="q-btn" @click="showQueuedScanTable = true">
              {{ quoteTitleList.queuedScan }}
              <span>
                {{ queuedScanList.length }}
              </span>
            </base-button>
          </div>

          <div class="row d-flex align-items-center justify-content-center">
            <div class="col-md-3 mt-5">
              <search-quote
                :searchData="
                  quoteTitleList.allQuotes == quoteTableTitle
                    ? dbQuotesRequestsList
                    : dbQuotesList
                "
                @search-quotes="searchQuotes"
                :tableName="quoteTableTitle"
              />
            </div>

            <div class="col-md-9">
              <shipment-detail-filters
                :filterData="quotesList"
                :showCarrierDropdown="showCarrierDropdown"
                :showBuyRateDropdown="showBuyRateDropdown"
                :showMarginDropdown="showMarginDropdown"
                :isSearchQuote="false"
              />
            </div>
          </div>

          <div class="row accepted-quotes">
            <div class="col-md-12">
              <queued-scan-table
                v-if="showQueuedScanTable"
                :tableName="quoteTitleList.queuedScan"
                :tableData="queuedScanList"
              />
              <quotes-table
                v-else
                :tableName="quoteTableTitle"
                :pagination="true"
                :tableData="
                  quoteTitleList.allQuotes == quoteTableTitle
                    ? quotesRequestsList
                    : quotesList
                "
                @get-all-quote="allQuotesApi"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftPanel from "@/layout/LeftPanel";
import Loader from "@/layout/Loader";
import SearchBar from "@/views/components/SearchBar";
import ProfileHeader from "@/layout/ProfileHeader";
import PageHeadingCard from "@/views/components/PageHeadingCard";
import SearchQuote from "@/views/quotes/SearchQuote.vue";
import ShipmentDetailFilters from "@/views/quotes/ShipmentDetailFilters.vue";
import QuotesTable from "@/views/quotes/QuotesTable.vue";
import QueuedScanTable from "@/views/quotes/QueuedScanTable.vue";
import moment from "moment";
import { mapGetters } from "vuex";
import { API, masterAPI } from "@/api/API";
import {
  checkEmptyValue,
  quoteStatus,
  getCreatedQuoteUserName,
  getQuoteNumber,
} from "@/helpers/utility";

export default {
  components: {
    LeftPanel,
    Loader,
    SearchBar,
    ProfileHeader,
    PageHeadingCard,
    SearchQuote,
    ShipmentDetailFilters,
    QuotesTable,
    QueuedScanTable,
  },
  data: function() {
    return {
      // Component's data properties
      activeMenuName: "Quotes",
      showLoader: false,
      selectedQuoteTab: true,
      myQuotesText: "My Quotes",
      teamQuotesText: "Team Quotes",

      quotesList: [],
      dbQuotesList: [],
      quoteStatusList: quoteStatus,

      showCarrierDropdown: true,
      showBuyRateDropdown: true,
      showMarginDropdown: true,

      quoteTitleList: {
        allQuotes: "All Quotes",
        savedQuotes: "Saved Quotes",
        acceptedQuotes: "Accepted Quotes",
        underReviewByClient: "Under review by client",
        queuedScan: "Queued scan",
      },
      quoteTableTitle: "All Quotes",
      queuedScanList: [],
      showQueuedScanTable: false,
      quotesRequestsList: [],
      dbQuotesRequestsList: [],
    };
  },
  computed: {
    ...mapGetters(["client", "allTeammatesUsers", "userDetail"]),
  },
  methods: {
    /**
     * Fetches all quotes from the API.
     */
    async getAllQuote() {
      this.showLoader = true;
      this.quotesList = [];
      this.dbQuotesList = [];

      let response = await masterAPI(
        API.API_ENDPOINT.quote,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      this.showLoader = false;
      if (response.status == 200) {
        let quoteList = response.data;
        this.setQuoteResponse(quoteList, false);
      }

      this.getAllQuoteRequests();
    },
    /**
     * Fetches all quotes requests from the API.
     */
    async getAllQuoteRequests() {
      this.quotesRequestsList = [];
      this.dbQuotesRequestsList = [];

      let response = await masterAPI(
        API.API_ENDPOINT.quoteRequest,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        let responseData = response.data;
        let quoteList = this.getQuoteRequestResponse(responseData);

        this.setQuoteResponse(quoteList, true);
      }
    },
    /**
     * Fetches team quotes from the API.
     */
    async getAllTeamUsersQuote() {
      this.showLoader = true;
      this.quotesList = [];
      this.dbQuotesList = [];
      let accountId = this.userDetail.accountId;

      let endPoint =
        API.API_ENDPOINT.quote + "/" + accountId + "/" + API.API_ENDPOINT.team;
      let response = await masterAPI(
        endPoint,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      this.showLoader = false;
      if (response.status == 200) {
        let quoteList = response.data;
        this.setQuoteResponse(quoteList, false);
      }

      this.getAllTeamQuoteRequest();
    },
    /**
     * Fetches all team quotes requests from the API.
     */
    async getAllTeamQuoteRequest() {
      this.quotesRequestsList = [];
      this.dbQuotesRequestsList = [];
      let accountId = this.userDetail.accountId;

      let endPoint =
        API.API_ENDPOINT.quoteRequest +
        "/" +
        accountId +
        "/" +
        API.API_ENDPOINT.team;
      let response = await masterAPI(
        endPoint,
        API.API_METHOD.get,
        undefined,
        undefined
      );
      if (response.status == 200) {
        let responseData = response.data;
        let quoteList = this.getQuoteRequestResponse(responseData);

        this.setQuoteResponse(quoteList, true);
      }
    },
    /**
     * Sets the quote response data.
     * @param {Array} quoteList - List of quotes received from the API.
     */
    setQuoteResponse(quoteList, isQuoteRequest) {
      quoteList.forEach(item => {
        let createdAt = moment(item.createdAt).format("MMM Do, YYYY");
        let quoteDetail = item.details;
        // Instead of rates[0], store the entire rates array
        let rates = quoteDetail.rates;
        let ratesData = {
          firstRate: rates[0] || {}, // If no rates, set to empty object
        };
        let carrierName = ratesData.firstRate.lane?.carrier?.name || "";
        let carrierRate = ratesData.firstRate.totalCostBeforeMargin || "";
        let margin = ratesData.firstRate.marginCost?.value || "";
        let origin = ratesData.firstRate.lane?.origin || "";
        let destination = ratesData.firstRate.lane?.destination || "";
        let total = checkEmptyValue(ratesData.firstRate.totalCost) || "";
        let baseRate = ratesData.firstRate.baseRate || "";

        let clientName = this.client
          .map(items => {
            if (items.id == item.clientId) {
              return items;
            }
          })
          .filter(function(element) {
            return element !== undefined;
          });

        let status = item.quoteStatus;

        let quoteItem = {
          select: false,
          quotenumber: getQuoteNumber(item.id),
          clientName: clientName.length != 0 ? clientName[0].name : "",
          origin: origin, // Use first rate for reference
          destination: destination,
          createdBy: getCreatedQuoteUserName(
            this.allTeammatesUsers,
            item.userId
          ),
          createdAt: createdAt,
          shipmendetails:
            "Volumetric Weight: " + quoteDetail.totalVolumetricWeight,
          status: status,
          createTemplate: "Create",
          edit: item.id,
          details: quoteDetail,
          createTemplateBtn: true,
          clientId: item.clientId,
          buyRate: carrierRate,
          margin: margin,
          carrierName: carrierName,
          rates: rates,
          total: total,
          baseRate: baseRate,
          quoteItem: item,
          currencyType:
            clientName.length != 0 ? clientName[0].currencyType : "",
        };

        if (isQuoteRequest) {
          this.quotesRequestsList.push(quoteItem);
          this.dbQuotesRequestsList.push(quoteItem);
        } else {
          this.quotesList.push(quoteItem);
          this.dbQuotesList.push(quoteItem);
        }

        if (!carrierName) {
          this.showCarrierDropdown = false;
        }

        if (!carrierRate) {
          this.showBuyRateDropdown = false;
        }

        if (!margin) {
          if (margin != 0) {
            this.showMarginDropdown = false;
          }
        }
      });
    },
    /**
     * Processes a list of quotes and extracts relevant details from those with valid rate responses.
     *
     * @param {Array} quoteList - List of quote objects to process.
     * @returns {Array} Filtered and formatted list of quote requests.
     */
    getQuoteRequestResponse(quoteList) {
      let updatedQuoteRequestList = quoteList
        .filter(
          quote => quote.response?.rates && quote.response.rates.length > 0
        ) // Ignore empty rates
        .map(quote => ({
          accountId: quote.accountId,
          clientId: quote.response?.client?.id || "",
          createdAt: quote.createdAt,
          details: {
            destination: quote?.destination || "",
            items: quote.items || [],
            metric: quote.metric || "",
            origin: quote?.origin || "",
            rates: quote.response?.rates || [],
            contact: quote.response?.contact || {},
            margin: quote.response?.margin || "",
            totalChargeableWeight: quote.response?.totalChargeableWeight || "",
            totalVolumetricWeight: quote.response?.totalVolumetricWeight || "",
            totalWeight: quote.response?.totalWeight || "",
          },
          id: quote.id,
          updatedAt: quote.updatedAt,
          userId: quote.userId,
        }));

      return updatedQuoteRequestList;
    },
    /**
     * Retrieves a list of quotes based on the specified status.
     * @param {string} status - The status of the quotes to retrieve.
     * @returns {Array} - An array of quotes that match the specified status.
     */
    getQuoteByStatus(status) {
      let list = this.dbQuotesList
        .map(item => {
          if (status == this.quoteStatusList.SAVED) {
            if (
              item.status == status ||
              item.status == this.quoteStatusList.SENT
            ) {
              return item;
            }
          } else {
            if (item.status == status) {
              return item;
            }
          }
        })
        .filter(function(element) {
          return element !== undefined;
        });
      return list;
    },
    /**
     * Updates the list of quotes with the provided item.
     * @param {Array} item - The new list of quotes.
     */
    searchQuotes(item) {
      this.quotesList = item;
      this.quotesRequestsList = item;
    },
    /**
     * Handles the quote tab selection and triggers the appropriate API call.
     * @param {string} activeTab - The selected quote tab.
     */
    handleQuoteTab(activeTab) {
      if (activeTab != this.selectedQuoteTab) {
        this.selectedQuoteTab = activeTab;
        this.allQuotesApi();
        this.quoteTableTitle = this.quoteTitleList.allQuotes;
        this.showQueuedScanTable = false;
      }
    },
    /**
     * Calls the appropriate API to retrieve all quotes based on the selected quote tab.
     */
    allQuotesApi() {
      if (this.selectedQuoteTab) {
        this.getAllQuote();
      } else {
        this.getAllTeamUsersQuote();
      }

      this.getAllScans();
    },
    /**
     * Retrieves the length of quotes based on their status.
     * @param {string} quoteStatus - The status of the quotes to count.
     *
     * @returns {number} - The length of quotes with the specified status.
     */
    getQuoteLengthByStatus(quoteStatus) {
      return this.getQuoteByStatus(quoteStatus).length;
    },
    /**
     * Filters quotes based on their status and updates the quotes list and table title accordingly.
     * @param {string} quoteStatus - The status of the quotes to filter.
     * @param {string} tableTitle - The title to set for the filtered quotes table.
     */
    filterQuote(quoteStatus, tableTitle) {
      this.showQueuedScanTable = false;

      if (quoteStatus == this.quoteTitleList.allQuotes) {
        // If status is "all", set quotes list to the full database quotes list
        this.quotesList = this.dbQuotesRequestsList;
      } else {
        // Otherwise, filter quotes by status and update quotes list
        this.quotesList = this.getQuoteByStatus(quoteStatus);
      }

      this.quotesList.forEach(item => {
        item.select = false;
      });

      // Update the table title
      this.quoteTableTitle = tableTitle;
    },
    /**
     * Retrieves all queued scans list
     */
    async getAllScans() {
      this.queuedScanList = [];

      let response = await masterAPI(
        API.API_ENDPOINT.scans,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        this.queuedScanList = response.data;
      }
    },
  },
  created() {
    let vm = this;
    this.showLoader = true;
    setTimeout(function() {
      vm.allQuotesApi();
    }, 1000);

    let queuedScan = this.$session.get("queued-scan");

    if (queuedScan) {
      this.quoteTableTitle = this.quoteStatusList.queuedScan;
      this.showQueuedScanTable = true;
      this.$session.remove("queued-scan");
    }
  },
};
</script>

<style>
.accepted-quotes {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-top: 30px;
}

.search-quotes-padding {
  padding-top: 30px;
}

.shipment-filter {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.shipment-filter .choices {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
}

.shipment-filter-text {
  font-weight: 700;
  font-size: 13px;
  line-height: 22px;
  color: #004225;
  padding-left: 5px;
  padding-right: 5px;
}

.quotes-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #000000;
}

.filter-button {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-top: 20px;
}

.quotes-page .nav.btn-group li {
  flex: 1 1 auto;
  padding: 0;
  text-align: center;
}

.quotes-page .nav.btn-group li a {
  margin: 0;
  box-shadow: none;
  border: 1px solid transparent;
}

.quotes-page .nav.btn-group li:first-child a {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.quotes-page .nav.btn-group li:last-child a {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tab-space {
  padding: 0px !important;
}

.quotes-page
  .nav-pills.nav-pills:not(.flex-column)
  .nav-item:not(:last-child)
  .nav-link {
  margin-bottom: 0px !important;
}

.quotes-page .nav {
  padding-left: 0px !important;
  padding-right: 15px !important;
}

.choices__list--single {
  padding: 0px 16px 0px 0px !important;
}

.common-btn-margin {
  margin-right: 15px !important;
}

@media only screen and (max-width: 768px) {
  .common-btn-margin {
    margin-bottom: 15px !important;
  }
}

.quotes-shipment-filter {
  align-items: center;
  display: flex;
  height: 67px;
  margin-right: 15px;
}

.back-btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.3px;
}
</style>
