<template>
  <div class="wrapper">
    <div class="body-container row add-edit-carrier">
      <loader :isLoader="isLoader"></loader>
      <left-panel :activeMenuName="activeMenuName"></left-panel>
      <div class="col-lg-10 right-panel-scroll plr-30 mt-4">
        <div class="row right-panel-header">
          <div class="col-md-4">
            <search-bar />
          </div>
          <div class="col-md-4"></div>
          <div class="col-md-4">
            <profile-header />
          </div>
        </div>

        <page-heading-card
          title="Add Carrier"
          subTitle=""
          buttonName="See all saved carrier"
          imageName="carriers.png"
          addRecordScreenName="carriers"
        />

        <div class="mt-5 mb-4 section-b">
          <ValidationObserver v-slot="{ validate }" ref="formValidator">
            <form
              @submit.prevent="validate().then(createNewCarriers)"
              class="needs-validation"
              id="create_client"
            >
              <div class="row mb-4">
                <div class="col-md-6 headline-30">
                  Add New Carrier
                </div>
                <div class="col-md-6">
                  <div class="save-carriers">
                    <base-button
                      nativeType="submit"
                      type="primary"
                      class="btn-theme-24876E"
                      :disabled="saveBtnDisabled"
                    >
                      Save
                    </base-button>
                  </div>
                </div>
              </div>

              <div
                v-for="(item, index) in carriersList"
                :key="index"
                class="row"
              >
                <div class="form-group has-label col-md-5">
                  <label>Carrier</label>
                  <div class="input-v1">
                    <vue-select
                      class="form-control p-1"
                      :options="supportedCarriers"
                      v-model="carriersList[index]"
                      label="name"
                      placeholder="Select or create a carrier"
                      :id="item.name"
                      :create-option="createCarrier"
                      taggable
                      @option:created="carrierCreated"
                    />
                  </div>
                </div>
                <base-input
                  v-for="input in item.inputs"
                  :key="input.name"
                  :type="input.type"
                  :name="input.name"
                  :label="input.label"
                  class="input-v1 ml-4"
                  v-model="input.value"
                />
                <div class="ml-4 delete-carriers">
                  <base-button
                    type="primary"
                    class="btn-theme-EFEBEB action-delete"
                    @click="removeCarrier(index)"
                  >
                    <img src="../assets/img/home/trash.png" />
                  </base-button>
                </div>
              </div>

              <base-button
                class="btn-theme-24876E mr-2"
                v-on:click="addCarriersRow"
              >
                <img src="../assets/img/home/plus-round.png" />
                Add carrier
              </base-button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSelect from "vue-select";
import LeftPanel from "../layout/LeftPanel";
import Loader from "../layout/Loader";
import SearchBar from "@/views/components/SearchBar";
import ProfileHeader from "@/layout/ProfileHeader";
import PageHeadingCard from "@/views/components/PageHeadingCard";
import { API, masterAPI } from "@/api/API";

export default {
  bodyClass: "carriers",
  components: {
    LeftPanel,
    Loader,
    VueSelect,
    SearchBar,
    ProfileHeader,
    PageHeadingCard
  },
  data: function() {
    return {
      carriersList: [
        {
          name: "",
          inputs: [
            { name: "apiKey", type: "text", value: "", label: "API Key" }
          ]
        }
      ],
      supportedCarriers: [],
      selectedCarrier: null,
      activeMenuName: "Carriers",
      saveBtnDisabled: false,
      isLoader: false
    };
  },
  methods: {
    carrierCreated(c) {
      console.log("option created", c);
    },
    createCarrier(carrier) {
      return {
        name: carrier,
        inputs: [{ name: "apiKey", type: "text", value: "", label: "API Key" }]
      };
    },
    addCarriersRow: function() {
      let carriersObj = {
        name: "",
        inputs: [{ name: "apiKey", type: "text", value: "", label: "API Key" }]
      };
      this.carriersList.push(carriersObj);
    },
    createNewCarriers: async function() {
      this.$refs.formValidator.validate().then(async isSuccess => {
        if (isSuccess) {
          this.saveBtnDisabled = true;
          this.isLoader = true;
          let carriers = this.carriersList;
          const supportedCarriersNames = this.supportedCarriers.map(
            carrier => carrier.name
          );
          let carrierType = "other";
          for (const carrier of carriers) {
            const credentials = {};
            if (supportedCarriersNames.includes(carrier.name)) {
              credentials.metadata = {
                credentials: carrier.inputs.reduce((acc, input) => {
                  acc[input.name] = input.value;
                  return acc;
                }, {})
              };
              carrierType = carrier.id;
            } else {
              // Setting on both to maintain backwards compatibility
              // Eventually we'll remove the tariffApiKey field
              credentials.tariffApiKey = carrier.inputs[0].value;
              credentials.metadata = {
                credentials: { tariffApiKey: carrier.inputs[0].value }
              };
            }
            let bodyPayload = JSON.stringify({
              name: carrier.name,
              carrierType,
              ...credentials
            });

            let response = await masterAPI(
              API.API_ENDPOINT.carrier,
              API.API_METHOD.post,
              undefined,
              bodyPayload
            );

            if (response.status === 200) {
              this.isLoader = false;
              this.$toast.success("Carriers create successful", {
                timeout: 1000
              });
              this.$router
                .push({ path: "/carriers", name: "carriers" })
                .catch(() => {});
            } else {
              this.isLoader = false;
              this.saveBtnDisabled = false;
              this.$toast.error("Something went wrong");
            }
          }
        }
      });
    },
    removeCarrier(carrierIndex) {
      if (this.carriersList.length > 1) {
        this.carriersList.splice(carrierIndex, 1);
      } else {
        this.$toast.error("At least one carrier required");
      }
    }
  },
  created() {
    let vm = this;
    let carriersCount = localStorage.getItem("carriersCount");
    if (carriersCount != null) {
      for (let i = 1; i < carriersCount; i++) {
        let carriersObj = {
          name: "",
          inputs: [
            { name: "apiKey", type: "text", value: "", label: "API Key" }
          ]
        };
        vm.carriersList.push(carriersObj);
      }
      localStorage.removeItem("carriersCount");
    }
  },
  async mounted() {
    const response = await masterAPI(
      API.API_ENDPOINT.supportedCarriers,
      API.API_METHOD.get
    );
    if (response.status === 200) {
      this.supportedCarriers = Object.entries(response.data).reduce(
        (result, [key, val]) => {
          if (val.name) {
            result.push({
              name: val.name,
              id: key,
              inputs: val.credentials
            });
          }
          return result;
        },
        []
      );
    }
  }
};
</script>

<style scoped>
/deep/ {
  --vs-border-color: none;
  --vs-dropdown-bg: #f4f4f4;
  --vs-selected-bg: #24876e;
  --vs-search-input-bg: #f4f4f4;
  --vs-dropdown-option--active-bg: #24876e;
  --vs-dropdown-option--active-color: #eeeeee;
}

.file-btn {
  cursor: pointer;
}
</style>
