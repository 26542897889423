<template>
  <div>
    <!-- lbs kgs tab component -->
    <lbs-kgs-tab
      v-if="!createQuote"
      :selectedTab="selectedTab"
      :addShipmentItem="addShipmentItem"
      @change-tab="changeTab"
    />

    <ValidationObserver v-slot="{ validate }" ref="formValidator">
      <form class="needs-validation" @submit.prevent="validate()">
        <div v-if="createQuote">
          <div
            class="row mt-3"
            v-for="(item, index) in addShipmentItem"
            :key="index"
          >
            <!--            <div class="form-group has-label col-md-1">-->
            <!--              <label> View Scans </label>-->
            <!--              <div-->
            <!--                class="c-p"-->
            <!--                v-on:click="scanItems()"-->
            <!--              >-->
            <!--                <img src="../../assets/img/scan.png" width="100%"/>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="col-sm-2 col-md-2 additional-piece-responsive input-v1">
              <base-input
                type="number"
                label="Pieces"
                step="0.00"
                placeholder="Pieces"
                name="Pieces"
                :rules="handleShipmentValidation ? 'required' : ''"
                v-model="item.quantity"
                @change="pieceCalculation(index)"
                v-on:keypress="positiveNumbersOnly"
              >
              </base-input>
            </div>
            <div class="col-4 col-md-2 lwh-res input-v1">
              <base-input
                type="number"
                :label="'Length (' + setPlaceholderToShipment() + ')'"
                step="0.01"
                placeholder="0"
                name="Length"
                :rules="handleShipmentValidation ? 'required' : ''"
                v-model="item.length"
                @change="pieceCalculation(index)"
                v-on:keypress="positiveNumbersOnly"
              >
              </base-input>
              <i class="fa fa-close close-icon"></i>
            </div>
            <div class="col-4 col-md-2 lwh-res input-v1">
              <base-input
                type="number"
                :label="'Width (' + setPlaceholderToShipment() + ')'"
                step="0.01"
                placeholder="0"
                name="Width"
                :rules="handleShipmentValidation ? 'required' : ''"
                v-model="item.width"
                @change="pieceCalculation(index)"
                v-on:keypress="positiveNumbersOnly"
              >
              </base-input>
              <i class="fa fa-close close-icon"></i>
            </div>
            <div class="col-4 col-md-2 lwh-res input-v1">
              <base-input
                type="number"
                :label="'Height (' + setPlaceholderToShipment() + ')'"
                step="0.01"
                placeholder="0"
                name="Height"
                :rules="handleShipmentValidation ? 'required' : ''"
                v-model="item.height"
                @change="pieceCalculation(index)"
                v-on:keypress="positiveNumbersOnly"
              >
              </base-input>
            </div>
            <div class="col-9 col-md-2 additional-piece-responsive input-v1">
              <base-input
                type="number"
                :label="'Per Piece Weight (' + selectedTab + ')'"
                step="0.01"
                name="Weight"
                :rules="handleShipmentValidation ? 'required' : ''"
                placeholder="0"
                v-model="item.weight"
                @change="pieceCalculation(index, true)"
                v-on:keypress="positiveNumbersOnly"
              >
              </base-input>
            </div>
            <div class="col-9 col-md-2 additional-piece-responsive input-v1">
              <base-input
                type="number"
                :label="'Total Weight (' + selectedTab + ')'"
                step="0.01"
                name="totalWeight"
                :rules="handleShipmentValidation ? 'required' : ''"
                placeholder="0"
                v-model="item.totalWeight"
                @change="pieceCalculation(index)"
                v-on:keypress="positiveNumbersOnly"
              />
            </div>
            <!-- NMFC start -->
            <div class="col-sm-2 col-md-2 additional-piece-responsive input-v1">
              <base-input
                type="text"
                label="NMFC"
                name="NMFC"
                v-model="item.nmfc"
              >
              </base-input>
            </div>
            <div class="col-sm-2 col-md-2 additional-piece-responsive input-v1">
              <base-input type="text" label="Sub" name="Sub" v-model="item.sub">
              </base-input>
            </div>

            <div class="col-sm-2 col-md-2 additional-piece-responsive input-v1">
              <base-input
                type="text"
                label="PCF"
                name="PCF"
                :disabled="true"
                v-model="item.density"
              >
              </base-input>
            </div>
            <div class="col-sm-2 col-md-2 freight-dropdown">
              <label>Freight Class</label>
              <choices-single
                :id="'freightClassDropdown-' + index"
                :options="freightClassList"
                v-model="item.freightClass"
                :value="item.freightClass"
                :key="item.freightClass"
              >
                <!-- Default Select option if no value is selected -->
                <option v-if="!item.freightClass" value="0">Select</option>

                <!-- Options for freightClass -->
                <option> {{ item.freightClass }}</option>
              </choices-single>
            </div>
            <div class="col-3 col-md-1 delete-icon">
              <base-button
                type="success"
                class="btn-theme-EFEBEB delete-pieces"
                v-on:click="removePiece(index)"
              >
                <img src="../../assets/img/home/trash.png" />
              </base-button>
            </div>
            <!-- NMFC end -->

            <add-pickup-delivery-time
              v-if="index === addShipmentItem.length - 1"
              :pickup-delivery-date.sync="pickupDeliveryDate"
            />
          </div>

          <!-- Historical Rates -->
          <tariff-versioned
            :isCreateQuote="true"
            :historical-rates-date.sync="historicalRatesDate"
            :carriers-list="carriersList"
            :selected-carriers.sync="selectedCarriers"
            :add-historical-rates-label.sync="addHistoricalRatesLabel"
            :isQuotesEdit="isQuotesEdit"
          />

          <div class="mb-3 m-shipment-btn">
            <base-button
              type="success"
              class="clear-all-btn mr-2 mb-2"
              v-on:click="clearAllPiece"
            >
              <img src="../../assets/img/home/trash.png" />
              Clear all items
            </base-button>
            <base-button
              class="btn-theme-24876E mr-2 mb-2"
              v-on:click="addPiece"
            >
              Add more item
              <img src="../../assets/img/home/plus-round.png" />
            </base-button>
            <base-button class="btn-theme-F1C40F mb-2" v-on:click="scanItems">
              View Scans
              <img class="ml-1" width="24" src="../../assets/img/scan.png" />
            </base-button>
          </div>
        </div>
        <div v-else>
          <div class="mt-3">
            <div
              class="dimensions-title"
              v-for="(item, index) in addShipmentItem"
              :key="index"
            >
              <div class="row">
                <div class="col-md-12 input-v1">
                  <base-input
                    type="number"
                    label="Quantity"
                    step="0.00"
                    placeholder="Quantity"
                    name="Quantity"
                    rules="required"
                    v-model="item.quantity"
                    @change="pieceCalculation(index)"
                    v-on:keypress="positiveNumbersOnly"
                  >
                  </base-input>
                  <div class="add-remove-buttons input-group-append">
                    <span class="input-group-text">
                      <span
                        v-on:click="decreaseQuantity(index)"
                        class="remove-piece-btn"
                      >
                        <svg
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.4668 3.33335C6.78489 3.33335 3.80012 6.31812 3.80012 10C3.80012 13.6819 6.78489 16.6667 10.4668 16.6667C14.1487 16.6667 17.1335 13.6819 17.1335 10C17.1335 6.31812 14.1487 3.33335 10.4668 3.33335ZM2.13345 10C2.13345 5.39765 5.86441 1.66669 10.4668 1.66669C15.0692 1.66669 18.8001 5.39765 18.8001 10C18.8001 14.6024 15.0692 18.3334 10.4668 18.3334C5.86441 18.3334 2.13345 14.6024 2.13345 10Z"
                            fill="#524F5F"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6.30011 10C6.30011 9.53978 6.67321 9.16669 7.13344 9.16669H13.8001C14.2603 9.16669 14.6334 9.53978 14.6334 10C14.6334 10.4603 14.2603 10.8334 13.8001 10.8334H7.13344C6.67321 10.8334 6.30011 10.4603 6.30011 10Z"
                            fill="#524F5F"
                          />
                        </svg>
                      </span>
                      <span
                        v-on:click="increaseQuantity(index)"
                        class="add-piece-btn"
                      >
                        <svg
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.4668 3.33335C6.78492 3.33335 3.80015 6.31812 3.80015 10C3.80015 13.6819 6.78492 16.6667 10.4668 16.6667C14.1487 16.6667 17.1335 13.6819 17.1335 10C17.1335 6.31812 14.1487 3.33335 10.4668 3.33335ZM2.13348 10C2.13348 5.39765 5.86444 1.66669 10.4668 1.66669C15.0692 1.66669 18.8002 5.39765 18.8002 10C18.8002 14.6024 15.0692 18.3334 10.4668 18.3334C5.86444 18.3334 2.13348 14.6024 2.13348 10Z"
                            fill="#524F5F"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.4668 5.83331C10.927 5.83331 11.3001 6.20641 11.3001 6.66665V9.16665H13.8001C14.2603 9.16665 14.6334 9.53974 14.6334 9.99998C14.6334 10.4602 14.2603 10.8333 13.8001 10.8333H11.3001V13.3333C11.3001 13.7936 10.927 14.1666 10.4668 14.1666C10.0065 14.1666 9.63344 13.7936 9.63344 13.3333V10.8333H7.13344C6.67321 10.8333 6.30011 10.4602 6.30011 9.99998C6.30011 9.53974 6.67321 9.16665 7.13344 9.16665H9.63344V6.66665C9.63344 6.20641 10.0065 5.83331 10.4668 5.83331Z"
                            fill="#524F5F"
                          />
                        </svg>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row edit-quote-piece">
                <div class="col-4 col-md-4 input-v1">
                  <base-input
                    type="number"
                    :label="'L (' + setPlaceholderToShipment() + ')'"
                    step="0.01"
                    placeholder="0"
                    name="Length"
                    rules="required"
                    v-model="item.length"
                    @change="pieceCalculation(index)"
                    v-on:keypress="positiveNumbersOnly"
                  >
                  </base-input>
                  <i class="fa fa-close close-icon"></i>
                </div>
                <div class="col-4 col-md-4 input-v1">
                  <base-input
                    type="number"
                    :label="'W (' + setPlaceholderToShipment() + ')'"
                    step="0.01"
                    placeholder="0"
                    name="Width"
                    rules="required"
                    v-model="item.width"
                    @change="pieceCalculation(index)"
                    v-on:keypress="positiveNumbersOnly"
                  >
                  </base-input>
                  <i class="fa fa-close close-icon"></i>
                </div>
                <div class="col-4 col-md-4 input-v1">
                  <base-input
                    type="number"
                    :label="'H (' + setPlaceholderToShipment() + ')'"
                    step="0.01"
                    placeholder="0"
                    name="Height"
                    rules="required"
                    v-model="item.height"
                    @change="pieceCalculation(index)"
                    v-on:keypress="positiveNumbersOnly"
                  >
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-5 col-md-5 input-v1">
                  <base-input
                    type="number"
                    :label="'Per Piece Weight (' + selectedTab + ')'"
                    step="0.01"
                    name="Weight"
                    rules="required"
                    placeholder="0"
                    v-model="item.weight"
                    @change="pieceCalculation(index, true)"
                    v-on:keypress="positiveNumbersOnly"
                  >
                  </base-input>
                </div>
                <div class="col-5 col-md-5 input-v1">
                  <base-input
                    type="number"
                    :label="'Total Weight (' + selectedTab + ')'"
                    step="0.01"
                    name="totalWeight"
                    rules="required"
                    placeholder="0"
                    v-model="item.totalWeight"
                    @change="pieceCalculation(index)"
                    v-on:keypress="positiveNumbersOnly"
                  />
                </div>
                <div class="col-2 col-md-2">
                  <span
                    class="remove-piece-icon"
                    v-on:click="removePiece(index)"
                  >
                    <img src="../../assets/img/home/trash.png" />
                  </span>
                </div>
              </div>

              <div class="row">
                <!-- NMFC start -->
                <div
                  class="col-sm-2 col-md-4 additional-piece-responsive input-v1"
                >
                  <base-input
                    type="text"
                    label="NMFC"
                    name="NMFC"
                    v-model="item.nmfc"
                  >
                  </base-input>
                </div>
                <div
                  class="col-sm-2 col-md-4 additional-piece-responsive input-v1"
                >
                  <base-input
                    type="text"
                    label="Sub"
                    name="Sub"
                    v-model="item.sub"
                  >
                  </base-input>
                </div>
                <div
                  class="col-sm-2 col-md-4 additional-piece-responsive input-v1"
                >
                  <base-input
                    type="text"
                    label="PCF"
                    name="PCF"
                    :disabled="true"
                    v-model="item.density"
                  >
                  </base-input>
                </div>
                <div class="col-sm-3 col-md-6 freight-dropdown has-label">
                  <label>Freight Class</label>
                  <choices-single
                    :id="'freightClassDropdown-' + index"
                    :options="freightClassList"
                    v-model="item.freightClass"
                    :value="item.freightClass"
                    :key="item.freightClass"
                  >
                    <!-- Default Select option if no value is selected -->
                    <option v-if="!item.freightClass" value="0">Select</option>

                    <!-- Options for freightClass -->
                    <option> {{ item.freightClass }}</option>
                  </choices-single>
                </div>
                <!-- NMFC end -->

                <add-pickup-delivery-time
                  v-if="index === addShipmentItem.length - 1"
                  :pickup-delivery-date.sync="pickupDeliveryDate"
                />
              </div>
            </div>
            <base-button
              v-if="isShowConfirmPieceBtn"
              class="btn-theme-24876E"
              v-on:click="confirmPiece"
            >
              Confirm Piece
            </base-button>
            <base-button v-else class="btn-theme-24876E" v-on:click="addPiece">
              Add Piece
              <img src="../../assets/img/home/plus-round.png" />
            </base-button>

            <!-- Historical Rates -->
            <tariff-versioned
              :isCreateQuote="false"
              :historical-rates-date.sync="historicalRatesDate"
              :carriers-list="carriersList"
              :selected-carriers.sync="selectedCarriers"
              :add-historical-rates-label.sync="addHistoricalRatesLabel"
              :isQuotesEdit="isQuotesEdit"
            />
          </div>
          <hr style="width:100%" />
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { API, masterAPI } from "@/api/API";
import { checkEmptyShipmentItem, checkEmptyValue } from "@/helpers/utility";
import LbsKgsTab from "@/views/quotes/LbsKgsTab";
import AddPickupDeliveryTime from "@/views/quotes/AddPickupDeliveryTime";
import ChoicesSingle from "@/components/SingleSelect";
import Vue from "vue";
import TariffVersioned from "./TariffVersioned.vue";

export default {
  components: {
    LbsKgsTab,
    AddPickupDeliveryTime,
    ChoicesSingle,
    TariffVersioned,
  },
  data: function() {
    return {
      // Component's data properties
      lbsLabel: "lbs",
      kgsLabel: "kgs",
      inchesLabel: "inches",
      cmsLabel: "cms",
      //
      addShipmentItem: [
        {
          quantity: 1,
          length: "",
          width: "",
          height: "",
          weight: "",
          totalWeight: "",
          chargeableWeight: "",
          originalLength: "",
          originalWidth: "",
          originalHeight: "",
          originalWeight: "",
          showItem: false,
          nmfc: "",
          sub: "",
        },
      ],
      pickupDeliveryDate: {
        addPickupDeliveryDateLabel: true,
        pickupDate: "",
        deliveryDate: "",
      },
      isShowConfirmPieceBtn: false,
      totalChargeableWeight: "",
      totalVolumetricWeight: "",
      totalWeight: "",
      shipmentLoader: false,
      totalNumOfPallets: "",
      freightClassList: [
        {
          id: 1,
          text: "Class 50",
        },
        {
          id: 2,
          text: "Class 55",
        },
        {
          id: 3,
          text: "Class 60",
        },
        {
          id: 4,
          text: "Class 65",
        },
        {
          id: 5,
          text: "Class 70",
        },
        {
          id: 6,
          text: "Class 77.5",
        },
        {
          id: 7,
          text: "Class 85",
        },
        {
          id: 8,
          text: "Class 92.5",
        },
        {
          id: 9,
          text: "Class 100",
        },
        {
          id: 10,
          text: "Class 110",
        },
        {
          id: 11,
          text: "Class 125",
        },
        {
          id: 12,
          text: "Class 150",
        },
        {
          id: 13,
          text: "Class 175",
        },
        {
          id: 14,
          text: "Class 200",
        },
        {
          id: 15,
          text: "Class 250",
        },
        {
          id: 16,
          text: "Class 300",
        },
        {
          id: 17,
          text: "Class 400",
        },
        {
          id: 18,
          text: "Class 500",
        },
      ],
      historicalRatesDate: "",
      carriersList: [],
      selectedCarriers: [],
      addHistoricalRatesLabel: true,
      isQuotesEdit: false,
    };
  },
  props: {
    // Component props
    selectedTab: {
      type: String,
    },
    additionalPieceItem: {
      type: Array,
    },
    nonStackable: {
      type: Boolean,
    },
    handleShipmentValidation: {
      type: Boolean,
    },
    createQuote: {
      type: Boolean,
    },
  },
  watch: {
    /** Watcher for the addShipmentItem property. */
    additionalPieceItem: {
      handler: function(value) {
        this.addShipmentItem = value;
      },
    },
    /** Watcher for the `pickupDeliveryDate` object to detect changes. */
    pickupDeliveryDate: {
      handler(newVal) {
        // Emit the updated pickup and delivery dates
        this.$emit(
          "update-pickup-delivery-date",
          newVal.pickupDate,
          newVal.deliveryDate,
        );
      },
      deep: true, // Ensures that nested properties within `pickupDeliveryDate` are watched
    },
    /** Watcher for the `historicalRatesDate` object to detect changes. */
    historicalRatesDate: {
      handler(newVal) {
        this.$emit("update-historical-rates-date", newVal);
      },
    },
    /** Watcher for the `selectedCarriers` object to detect changes. */
    selectedCarriers: {
      handler(newVal) {
        this.$emit("update-selected-carrier", newVal);
      },
      deep: true, // Ensures that nested properties within `pickupDeliveryDate` are watched
    },
  },
  methods: {
    /** Retrieves the pickup and delivery dates from session or API response */
    retrieveAndSetQuoteDetails() {
      // Fetch the saved quote details from session storage
      let saveQuoteDetail = Vue.prototype.$session.get("save_quote_details");

      if (saveQuoteDetail) {
        let quoteDetail = JSON.parse(saveQuoteDetail);

        // Assigning the pickupDate from the response dynamically
        this.pickupDeliveryDate.pickupDate =
          quoteDetail.response?.metadata?.pickupDate;
        this.pickupDeliveryDate.deliveryDate =
          quoteDetail.response?.metadata?.deliveryDate;

        // If pickupDate exists, the label will not be shown
        if (this.pickupDeliveryDate.pickupDate) {
          this.pickupDeliveryDate.addPickupDeliveryDateLabel = false;
        }
        // Assign historicalRatesDate from the response metadata
        this.historicalRatesDate =
          quoteDetail.response?.metadata?.historicalRatesDate;

        let carrierIds = quoteDetail.response?.metadata?.carrierIds || []; // The array of selected carrier IDs

        // Filter the selectedCarriers based on the carrierIds and extract the 'name' field
        this.selectedCarriers = this.carriersList.filter(carrier =>
          carrierIds.includes(carrier.id),
        ); // Filter carriers whose id is in carrierIds

        // Check if the historical rates label should be visible
        this.checkHistoricalRatesLabelVisibility();
      }
    },
    /** Checks whether the historical rates label should be visible or not */
    checkHistoricalRatesLabelVisibility() {
      if (!this.createQuote) {
        if (!this.historicalRatesDate || this.selectedCarriers.length === 0) {
          this.addHistoricalRatesLabel = true; // Show label
        } else {
          this.addHistoricalRatesLabel = false; // Show inputs only
        }
      }
    },
    setFreightClassText() {
      this.$nextTick(() => {
        // Loop through each item and set its displayed freight class text
        this.addShipmentItem.forEach((item, index) => {
          const dropdown = this.$refs[`freightClassDropdown-${index}`];
          if (dropdown && dropdown.choicesInstance) {
            dropdown.choicesInstance.setChoiceByValue(item.freightClass); // Set the initial value
          }
        });
      });
    },
    scanItems() {
      // Shows queued scan table
      this.$session.set("queued-scan", true);
      this.$router.push({ name: "quotes" });

      // this.scanDimensionsApi(index);
    },
    /** Adds a new piece to the shipment. */
    addPiece() {
      let addShipmentItemObj = {
        quantity: 1,
        length: "",
        width: "",
        height: "",
        weight: "",
        totalWeight: "",
        chargeableWeight: "",
        originalLength: "",
        originalWidth: "",
        originalHeight: "",
        originalWeight: "",
        showItem: true,
        nmfc: "",
        sub: "",
      };
      this.addShipmentItem.push(addShipmentItemObj);
      this.isShowConfirmPieceBtn = true;
    },
    /** Removes all pieces from the shipment. */
    clearAllPiece() {
      if (this.addShipmentItem.length > 1) {
        const firstItem = this.addShipmentItem[0];
        this.addShipmentItem = [];
        this.addShipmentItem.push(firstItem);
        this.chargeableWeightApi();
      } else {
        this.$toast.error("Atleast one shipment item require");
      }
    },
    /**
     * Removes a specific piece from the shipment.
     * @param {number} index - The index of the piece to remove.
     */
    removePiece(index) {
      if (this.addShipmentItem.length > 1) {
        this.addShipmentItem.splice(index, 1);
        this.chargeableWeightApi();
        this.isShowConfirmPieceBtn = false;
      } else {
        this.$toast.error("Atleast one shipment item require");
      }
    },
    /**
     * Calculates the properties of a piece (length, width, height).
     * @param {number} i - The index of the piece in the shipment.
     * @param {boolean} isPiece - Whether the input being updated is the piece weight.
     */
    pieceCalculation(i, isPiece) {
      let currentPiece = this.addShipmentItem[i];
      this.addShipmentItem[i].originalLength = currentPiece.length;
      this.addShipmentItem[i].originalWidth = currentPiece.width;
      this.addShipmentItem[i].originalHeight = currentPiece.height;
      this.addShipmentItem[i].originalWeight = currentPiece.weight;
      if (isPiece) {
        this.addShipmentItem[i].totalWeight =
          currentPiece.weight * currentPiece.quantity;
      } else {
        const divided = currentPiece.totalWeight / currentPiece.quantity;
        const rounded = Math.round((divided + Number.EPSILON) * 100) / 100;
        this.addShipmentItem[i].weight = rounded;
      }
      this.addShipmentItem[i].nmfc = currentPiece.nmfc;
      this.addShipmentItem[i].sub = currentPiece.sub;
      this.chargeableWeightApi();
    },
    /**
     * Allows only positive numbers in an input field.
     * @param {Event} evt - The input event.
     * @returns {boolean} - Returns true if the input is valid.
     */
    positiveNumbersOnly(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    /**
     * Sets the placeholder text based on the selected tab.
     * @returns {string} - The placeholder text.
     */
    setPlaceholder() {
      let label =
        this.selectedTab == this.lbsLabel ? this.inchesLabel : this.cmsLabel;
      return label;
    },
    async scanDimensionsApi(index) {
      let response = await masterAPI(
        API.API_ENDPOINT.scanDimensions,
        API.API_METHOD.post,
        undefined,
        {},
      );
      if (response.status === 200) {
        const dimensions = response.data;
        this.addShipmentItem[index].length = dimensions.length;
        this.addShipmentItem[index].width = dimensions.width;
        this.addShipmentItem[index].height = dimensions.height;
        this.addShipmentItem[index].weight = dimensions.weight;
      }
    },
    /** Calls the chargeable weight API to calculate total chargeable weight, volume, and weight. */
    async chargeableWeightApi() {
      let shipmentItemResult = checkEmptyShipmentItem(this.addShipmentItem);
      let additionalPieceList = this.addShipmentItem;
      if (shipmentItemResult.length == additionalPieceList.length) {
        this.totalChargeableWeight = "";
        this.totalVolumetricWeight = "";
        this.totalWeight = "";
        this.totalNumOfPallets = "";
        this.shipmentLoader = true;

        let items = [];
        additionalPieceList.map(item => {
          items.push({
            quantity: item.quantity,
            length: item.length,
            width: item.width,
            height: item.height,
            weight: item.weight,
            totalWeight: item.totalWeight,
            nmfc: item.nmfc,
            sub: item.sub,
            freightClass: item.freightClass,
          });
        });

        let bodyPayload = JSON.stringify({
          metric: {
            volume: this.selectedTab == "lbs" ? "inches" : "cms",
            weight: this.selectedTab,
          },
          items: items,
          nonStackable: this.nonStackable,
        });

        let response = await masterAPI(
          API.API_ENDPOINT.findChargeableWeight,
          API.API_METHOD.post,
          undefined,
          bodyPayload,
        );

        if (response.status == 200) {
          let tCW = response.data.totalChargeableWeight;
          this.totalChargeableWeight = this.isFloat(tCW) ? tCW.toFixed(2) : tCW;
          this.totalVolumetricWeight = checkEmptyValue(
            response.data.totalShipmentVolumetricWeight,
          );
          this.totalNumOfPallets = checkEmptyValue(
            response.data.totalNumOfPallets,
          );

          let tW = response.data.totalShipmentWeight;
          this.totalWeight = this.isFloat(tW) ? tW.toFixed(2) : tW;
          this.shipmentLoader = false;

          // Map each density back to the addShipmentItem
          response.data.items.forEach((item, index) => {
            if (this.addShipmentItem[index]) {
              this.$set(
                this.addShipmentItem[index],
                "density",
                parseFloat(item.density).toFixed(2),
              ); // Round the density to 2 decimal places
            }
          });

          let weightDetail = {
            totalChargeableWeight: this.totalChargeableWeight,
            totalVolumetricWeight: this.totalVolumetricWeight,
            totalWeight: this.totalWeight,
            shipmentLoader: this.shipmentLoader,
            totalNumOfPallets: this.totalNumOfPallets, // Number of pallets accounted for in the shipment, based on dimensions
          };

          this.$emit("weight-detail", weightDetail);
        }
      }
    },
    /**
     * Handles the tab change event.
     * @param {Array} data - The updated shipment item list.
     * @param {string} tab - The newly selected tab.
     */
    changeTab(data, tab) {
      this.addShipmentItem = data;
      this.$emit("change-tab-name", data, tab);
    },
    /**
     * Toggles the visibility of an item in the pounds view.
     * @param {number} index - The index of the item in the shipment.
     * @param {Object} item - The item object.
     */
    showItemViewLbs(index, item) {
      if (!this.isShowConfirmPieceBtn) {
        this.addShipmentItem[index].showItem = !item.showItem;
      }
    },
    /** Confirms the added piece and performs form validation. */
    async confirmPiece() {
      // Perform form validation
      const isFormValid = await this.$refs.formValidator.validate();
      if (isFormValid) {
        let lastPieceIndex = this.addShipmentItem.length - 1;
        this.$toast.success("Piece has been successfully added.");
        this.isShowConfirmPieceBtn = false;
        this.addShipmentItem[lastPieceIndex].showItem = false;
        this.chargeableWeightApi();
      }
    },
    /**
     * Checks if a number is a float.
     * @param {number} n - The number to check.
     * @returns {boolean} - Returns true if the number is a float.
     */
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },
    /**
     * Sets the placeholder text based on the selected tab.
     * @returns {string} - The placeholder text.
     */
    setPlaceholderToShipment() {
      let label = this.selectedTab == this.lbsLabel ? "in." : "cm";
      return label;
    },
    /**
     * Increases the quantity of a shipment item by one.
     * @param {number} pieceIndex - The index of the shipment item to increase quantity.
     */
    increaseQuantity(pieceIndex) {
      let currentQuantity = this.addShipmentItem[pieceIndex].quantity;
      this.addShipmentItem[pieceIndex].quantity = parseInt(currentQuantity) + 1;
      // Recalculate weight
      this.pieceCalculation(pieceIndex);
    },
    /**
     * Decreases the quantity of a shipment item by one, if it's greater than 1.
     * @param {number} pieceIndex - The index of the shipment item to decrease quantity.
     */
    decreaseQuantity(pieceIndex) {
      let currentQuantity = this.addShipmentItem[pieceIndex].quantity;
      if (currentQuantity > 1) {
        this.addShipmentItem[pieceIndex].quantity =
          parseInt(currentQuantity) - 1;
      }
      // Recalculate weight
      this.pieceCalculation(pieceIndex);
    },
    /** Fills the shipment items with dimensions from the session storage. */
    fillDimensionsItems() {
      let dimensionsItems = this.$session.get("dimensions-items");
      if (dimensionsItems) {
        let items = [];
        dimensionsItems = JSON.parse(dimensionsItems);

        dimensionsItems.map(item => {
          items.push({
            quantity: "",
            ...item,
            originalLength: item.length,
            originalWidth: item.width,
            originalHeight: item.height,
            originalWeight: item.weight,
            showItem: true,
            nmfc: item.nmfc,
            sub: item.sub,
          });
        });

        this.addShipmentItem = items;
        this.$session.remove("dimensions-items");
      }
    },
    /** Fetches all carrier data from the API and processes it. */
    async getAllCarriers() {
      let response = await masterAPI(
        API.API_ENDPOINT.carrier,
        API.API_METHOD.get,
        undefined,
        undefined,
      );

      if (response.status == 200) {
        this.carriersList = response.data;
        // Add the "Select All" option at the top
        this.carriersList.unshift({
          id: "selectAll",
          name: "Select All",
          selectAll: true, // Custom flag to identify this option
        });

        if (this.createQuote) {
          // Select all non-"Select All" carriers by default
          const nonSelectAllItems = this.carriersList.filter(
            item => !item.selectAll,
          );
          this.selectedCarriers = [...nonSelectAllItems]; // Select all non-"Select All" items
        }
      }
    },
    /** Checks if the current quote is in edit mode based on session data. */
    checkIfQuoteIsInEditMode() {
      let isEditCheck = Vue.prototype.$session.get("save_quote_details");
      if (isEditCheck) {
        let quoteDetail = JSON.parse(isEditCheck);

        if (quoteDetail.isEdit) {
          this.isQuotesEdit = !!quoteDetail.isEdit;
        }
      }
    },
  },
  created() {
    if (!this.createQuote) {
      this.addShipmentItem = this.additionalPieceItem;
      this.chargeableWeightApi();
      this.retrieveAndSetQuoteDetails();
    } else {
      this.fillDimensionsItems();
    }
  },
  mounted() {
    if (!this.createQuote) {
      this.setFreightClassText();
      this.checkIfQuoteIsInEditMode();
    }
    this.getAllCarriers().then(() => {
      // Now that carriersList is populated, call retrieveAndSetQuoteDetails
      if (!this.createQuote) {
        this.retrieveAndSetQuoteDetails();
      }
    });
  },
};
</script>
<style>
.freight-dropdown .choices__inner {
  border: none !important;
}

.freight-dropdown .choices-dropdown-label {
  margin-bottom: 0px;
}

.freight-dropdown .choices__item--selectable {
  color: #8898aa;
  font-weight: 400;
  line-height: 1.5;
}
</style>
