export const pickupAccessorialsList = [
  {
    checked: false,
    name: "Appointment Pickup",
    key: "appointment_pickup",
    backgroundColor: "#EC0C38",
  },
  {
    checked: false,
    name: "Liftgate Pickup",
    key: "power_tailgate_pickup",
    backgroundColor: "#333333",
  },
  {
    checked: false,
    name: "Inside Pickup",
    key: "inside_pickup",
    backgroundColor: "#0DA5C0",
  },
  {
    checked: false,
    name: "Limited Access Pickup",
    key: "limited_access_pickup",
    backgroundColor: "#FA3A0E",
  },
  {
    checked: false,
    name: "Residential Pickup",
    key: "residential_pickup",
    backgroundColor: "#004225",
  },
];

export const deliveryAccessorialsList = [
  {
    checked: false,
    name: "Appointment Delivery",
    key: "appointment_delivery",
    backgroundColor: "#EC0C38",
  },
  {
    checked: false,
    name: "Liftgate Delivery",
    key: "power_tailgate_delivery",
    backgroundColor: "#333333",
  },
  {
    checked: false,
    name: "Inside Delivery",
    key: "inside_delivery",
    backgroundColor: "#0DA5C0",
  },
  {
    checked: false,
    name: "Limited Access Delivery",
    key: "limited_access_delivery",
    backgroundColor: "#FA3A0E",
  },
  {
    checked: false,
    name: "Residential Delivery",
    key: "residential_delivery",
    backgroundColor: "#004225",
  },
];

export const otherAccessorialsList = [
  {
    checked: false,
    name: "Non-Stackable",
    key: "non_stackable",
    backgroundColor: "#EC0C38",
  },
  {
    checked: false,
    name: "Dangerous Goods",
    key: "dangerous_goods",
    backgroundColor: "#333333",
  },
  {
    checked: false,
    name: "Freeze Protect",
    key: "freeze_protect",
    backgroundColor: "#0DA5C0",
  },
  {
    checked: false,
    name: "In bond",
    key: "in_bond",
    backgroundColor: "#FA3A0E",
  },
  {
    checked: false,
    name: "Overlength",
    key: "overlength",
    backgroundColor: "#004225",
  },
];

export function accessorials(accessorialsList) {
  resetAccessorialsCheckList();
  if (accessorialsList) {
    accessorialsList.map(accessorialItem => {
      pickupAccessorialsList.map(pickupItem => {
        if (convertCamelToSnake(accessorialItem.identifier) == pickupItem.key) {
          pickupItem.checked = true;
        }
      });

      deliveryAccessorialsList.map(pickupItem => {
        if (convertCamelToSnake(accessorialItem.identifier) == pickupItem.key) {
          pickupItem.checked = true;
        }
      });

      otherAccessorialsList.map(pickupItem => {
        if (convertCamelToSnake(accessorialItem.identifier) == pickupItem.key) {
          pickupItem.checked = true;
        }
      });
    });
  }

  let itemList = {
    pickupAccessorialItem: pickupAccessorialsList,
    deliveryAccessorialItem: deliveryAccessorialsList,
    othersAccessorialItem: otherAccessorialsList,
  };
  return itemList;
}

export function convertCamelToSnake(str) {
  return str.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
}

export function resetAccessorialsCheckList() {
  pickupAccessorialsList.map(pickupItem => {
    pickupItem.checked = false;
  });

  deliveryAccessorialsList.map(pickupItem => {
    pickupItem.checked = false;
  });

  otherAccessorialsList.map(pickupItem => {
    pickupItem.checked = false;
  });
}
